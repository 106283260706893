import type { Spectrum1D } from '../../model';
import { Filter } from '../../model';
import { FilterManager } from '../filter-manager';
import { generateID } from '../generate-id';
import { getColor as get1dColor } from '../get-color';

const getColor = (
  options: Partial<Spectrum1D.Datum>,
  usedColors: Record<string, string[] | undefined>
): { color?: string } => {
  if (options.display === undefined || options.display.color === undefined) {
    const color = get1dColor(false, usedColors['1d'] ?? []);

    if (usedColors['1d']) {
      usedColors['1d'].push(color);
    }

    return { color };
  }

  return {};
};

const preprocessing = (datum: Spectrum1D.Datum): void => {
  if (datum.info.isFid && datum.filters.some((f) => f.name === Filter.DigitalFilter.id)) {
    FilterManager.applyFilter(datum, [
      {
        id: Filter.DigitalFilter.id,
        name: Filter.DigitalFilter.name,
        options: {
          digitalFilterValue: datum.info.digitalFilter
        },
        isDeleteAllow: false
      }
    ]);
  }
};

export const initiateDatum1D = (
  options: Partial<Spectrum1D.Datum>,
  usedColors = {}
): Spectrum1D.Datum => {
  // Populate default values
  const datum: Spectrum1D.Datum = {
    shiftX: 0,
    id: generateID(),
    source: {
      jcampURL: undefined,
      file: {
        binary: '',
        name: '',
        extension: ''
      }
    },
    display: {
      name: generateID(),
      color: 'black',
      ...getColor(options, usedColors),
      isVisible: true,
      isPeaksMarkersVisible: true,
      isRealSpectrumVisible: true,
      isVisibleInDomain: true
    },
    info: {
      nucleus: ['1H'], // 1H, 13C, 19F, ...
      isFid: false,
      isComplex: false, // if isComplex is true that mean it contains real/ imaginary  x set, if not hid re/im button .
      dimension: 1
    },
    originalInfo: {
      nucleus: ['1H'], // 1H, 13C, 19F, ...
      isFid: false,
      isComplex: false, // if isComplex is true that mean it contains real/ imaginary  x set, if not hid re/im button .
      dimension: 1
    },
    meta: {},
    data: {
      x: new Float64Array(),
      re: new Float64Array(),
      im: new Float64Array()
    },
    peaks: {
      values: [],
      options: {}
    },
    integrals: {
      values: [],
      options: {
        sum: 100
      }
    },
    filters: [],
    ranges: {
      values: [],
      options: {
        sum: 100
      }
    }
  };

  Object.assign(datum, options);

  // reapply filters after load the original data
  FilterManager.reapplyFilters(datum);

  preprocessing(datum);

  return datum;
};
