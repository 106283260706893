import { Arr } from '@splotch/core-utils';
import type { FromJCAMPResult } from 'nmr-parser';
import { fromJCAMP } from 'nmr-parser';
import type { Spectrum1D } from '../../../model';
import type { FileUtils } from '../../file-utils';
import { initiateDatum1D } from '../initiate-datum-1d';

interface Component {
  data?: {
    x?: Float64Array;
    y?: Float64Array;
  };
}

interface Options {
  xy?: boolean;
  noContours?: boolean;
  keepOriginal?: boolean;
  display?: {
    name?: string;
  };
}

const getData = (spectra: Component[]): Spectrum1D.Data => {
  const x = spectra[0]?.data?.x ?? new Float64Array();
  const re = spectra[0]?.data?.y ?? new Float64Array();
  const im = spectra[1]?.data?.y ?? new Float64Array();

  if (x[0] > x[1]) {
    x.reverse();
    re.reverse();
    im.reverse();
  }

  return { x, re, im };
};

/**
 * @param parsedJcamp JCAMP file results
 * @param options Options for JCAMPResult
 * @returns 1D datum
 */
const fromParsedJcamp = (parsedJcamp: FromJCAMPResult, options: Options = {}): Spectrum1D.Datum => {
  const { dependentVariables, info, meta } = parsedJcamp;
  const data = getData(<Component[]>dependentVariables[0].components);

  if (Array.isArray(info.nucleus)) {
    // eslint-disable-next-line prefer-destructuring
    info.nucleus = info.nucleus[0];
  }

  const datum1D = initiateDatum1D({
    ...options,
    info,
    meta,
    data
  });

  return datum1D;
};

/**
 * @param spectra 1D datum
 * @param entry JCAMP file result
 * @param options Options for JCAMP file
 */
const addJcampSS = (
  spectra: Spectrum1D.Datum[],
  entry: FromJCAMPResult,
  options: Record<string, unknown>
): void => {
  const { dimension } = entry.info;

  if (dimension === 1) {
    spectra.push(fromParsedJcamp(entry, options));
  }
};

/**
 * @param spectra 1D datum
 * @param jcamp JCAMP file binary
 * @param options Options for JCAMP file
 */
const addJcamp = (
  spectra: Spectrum1D.Datum[],
  jcamp: ArrayBuffer | string,
  options: Record<string, unknown> = {}
): void => {
  const entries = fromJCAMP(jcamp, {
    noContour: true,
    xy: true,
    keepRecordsRegExp: /.*/,
    profiling: true
  });

  if (entries.length === 0) {
    return;
  }
  // Should be improved when we have a more complex case
  for (const entry of entries) {
    const { dependentVariables } = entry;
    const [{ components }] = dependentVariables;

    if (
      components &&
      ((Arr.isArray(components) && components.length > 0) ||
        (!Arr.isArray(components) && Arr.isArray(components.z) && components.z.length))
    ) {
      addJcampSS(spectra, entry, options);
    }
  }
};

export const jcamp = (files: FileUtils.LoadedFile[], _options: Options): Spectrum1D.Datum[] => {
  const spectra: Spectrum1D.Datum[] = [];

  files.forEach((file) => {
    addJcamp(spectra, file.binary, {
      display: {
        name: file.name
      },
      source: {
        jcampURL: file.jcampURL ? file.jcampURL : undefined,
        file
      }
    });
  });

  return spectra;
};
