/* eslint-disable react/no-danger */
import { Num } from '@splotch/core-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { getFloategrals, rationalize, solventToHtml } from '../../utils';
import { SpectrumContext } from './spectrum-store';

interface Props {
  show: boolean;
  onHide: () => void;
}

export const SupportingInfoModal = ({ show, onHide }: Props): React.ReactElement => {
  const [copySuccess, setCopySuccess] = useState(false);

  const [solvent, setSolvent] = useState('CDCl3');
  const [frequency, setFrequency] = useState(400.0);
  const [reversePeaks, setReversePeaks] = useState(false);
  const [showSupportingInfo, setShowSupportingInfo] = useState(false);

  const [{ correctedData, peaks = [] }] = useContext(SpectrumContext);

  const calcSupportingInfo = useCallback(() => {
    const ranges = peaks.map((i) => i.range);
    const floategrals = getFloategrals(correctedData, ranges);
    const rationals = rationalize(floategrals);

    let html = `<sup>1</sup>H NMR (${solventToHtml(solvent)}, ${frequency} MHz): δ `;

    for (let i = 0; i < peaks.length; i++) {
      const index = reversePeaks ? peaks.length - i - 1 : i;
      const curPeak = peaks[index];
      const { couplingFactors = '' } = curPeak;
      const couplingConstant = couplingFactors.length
        ? `, ${couplingFactors
            .trim()
            .split(',')
            .map((x) => `<em>J</em> = ${(Number(x) * frequency).toFixed(1)} Hz`)
            .join(', ')}`
        : '';

      html += `${curPeak.center.toFixed(
        2
      )} (${curPeak.multiplicity!}${couplingConstant}, ${rationals[index].toFixed(0)}H)`;
      if (i < peaks.length - 1) {
        // We have another peak
        html += ', ';
      }
    }

    return html;
  }, [reversePeaks, peaks, solvent, correctedData, frequency]);

  const toggleReversePeaks = useCallback((): void => {
    setReversePeaks(!reversePeaks);
  }, [reversePeaks]);

  const onCloseModal = (): void => {
    setCopySuccess(false);
    setShowSupportingInfo(false);
    onHide();
  };

  const allMultiplicitiesSelected = useMemo(
    (): boolean => !!peaks.length && peaks.every((i) => i.multiplicity),
    [peaks]
  );

  const copyToClipboard = (): void => {
    window.getSelection()!.selectAllChildren(document.querySelector('#supporting-info')!);
    document.execCommand('copy');
    setCopySuccess(true);
  };

  const tooltipText = peaks.length
    ? 'Select All Peak Multiplicities to Calculate Supporting Info'
    : 'Select Peaks to Calculate Supporting Info';

  return (
    <Modal show={show} onHide={onCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Supporting Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        First, please fill in the inputs below so we can properly calculate your supporting info!
        <Form.Group controlId='solvent-input'>
          <Form.Label>Enter Solvent:</Form.Label>
          <Form.Row>
            <Form.Control
              value={solvent}
              onChange={(e): void => {
                setSolvent(e.target.value);
              }}
              size='sm'
              custom
            />
          </Form.Row>
          <div id='solvent-output' style={{ minHeight: '24px' }}>
            <em dangerouslySetInnerHTML={{ __html: solventToHtml(solvent) }} />
          </div>
        </Form.Group>
        <Form.Group controlId='frequency-input'>
          <Form.Label>Machine Frequency</Form.Label>
          <Form.Row>
            <InputGroup>
              <Form.Control
                aria-label='Machine Frequency'
                aria-describedby='machine-frequency'
                type='number'
                step='1'
                value={frequency}
                onChange={(e): void => {
                  setFrequency(Num.normalize(e.target.value)!);
                }}
                size='sm'
                custom
              />
              <InputGroup.Append>
                <InputGroup.Text id='machine-frequency'>MHz</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Row>
        </Form.Group>
        <Form.Group className='text-center'>
          <OverlayTrigger
            overlay={
              !allMultiplicitiesSelected ? (
                <Tooltip id='tooltip-disabled'>{tooltipText}</Tooltip>
              ) : (
                <span />
              )
            }
          >
            <Button
              variant='primary'
              disabled={!allMultiplicitiesSelected}
              onClick={(): void => {
                setShowSupportingInfo(true);
              }}
            >
              Calculate!
            </Button>
          </OverlayTrigger>
        </Form.Group>
        <Collapse in={showSupportingInfo}>
          <div>
            <hr />
            <Alert variant='primary'>
              <div
                id='supporting-info'
                dangerouslySetInnerHTML={{ __html: calcSupportingInfo() }}
              />
            </Alert>
            <Form.Row>
              <Col>
                <Form.Group controlId='reverse-peaks-checkbox'>
                  <Form.Check
                    type='checkbox'
                    label='Reverse Peaks'
                    checked={reversePeaks}
                    onChange={toggleReversePeaks}
                  />
                </Form.Group>
              </Col>
              <Col className='text-right'>
                <Form.Group>
                  <Button variant='outline-success' onClick={copyToClipboard}>
                    {copySuccess ? 'Copied!' : 'Copy to Clipboard'}
                  </Button>
                </Form.Group>
              </Col>
            </Form.Row>
          </div>
        </Collapse>
      </Modal.Body>
    </Modal>
  );
};
