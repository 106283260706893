import type { Theme } from '@splotch/core-ui';
import React from 'react';
import { withTheme } from 'styled-components';

const DEFAULT_SIZE = 48;

type Props = React.SVGProps<SVGSVGElement> &
  Theme.Props & {
    size?: number;
  };

export const MethaneLogo = withTheme(
  ({ theme, size = DEFAULT_SIZE, ...rest }: Props): React.ReactElement => (
    <svg width={size} height={size} viewBox='0 0 115 115' {...rest}>
      <defs>
        <linearGradient id='linearGradient2892'>
          <stop offset='0' />
          <stop stopColor={theme.colors.light.hex()} offset='1' />
        </linearGradient>
        <radialGradient
          id='radialGradient6459'
          cx='171.21'
          cy='196.85'
          r='200'
          gradientTransform='matrix(.13005 .097067 -.10181 .12975 96.505 91.965)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.colors.secondary.hex()} offset='0' />
          <stop offset='1' />
        </radialGradient>
        <radialGradient
          id='radialGradient6459-5'
          cx='171.21'
          cy='196.85'
          r='200'
          gradientTransform='matrix(.052021 .038802 -.040726 .051866 92.506 90.144)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.colors.white.hex()} offset='0' />
          <stop stopColor={theme.colors.secondary.hex()} offset='1' />
        </radialGradient>
        <radialGradient
          id='radialGradient6459-5-7'
          cx='171.21'
          cy='196.85'
          r='200'
          gradientTransform='matrix(.052021 .038802 -.040726 .051866 134.88 63.916)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.colors.white.hex()} offset='0' />
          <stop stopColor={theme.colors.secondary.hex()} offset='1' />
        </radialGradient>
        <radialGradient
          id='radialGradient6459-5-7-8'
          cx='171.21'
          cy='196.85'
          r='200'
          gradientTransform='matrix(.052021 .038802 -.040726 .051866 140.33 169.03)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.colors.white.hex()} offset='0' />
          <stop stopColor={theme.colors.secondary.hex()} offset='1' />
        </radialGradient>
        <radialGradient
          id='radialGradient6459-5-7-6'
          cx='171.21'
          cy='196.85'
          r='200'
          gradientTransform='matrix(.052021 .038802 -.040726 .051866 57.971 157.03)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.colors.white.hex()} offset='0' />
          <stop stopColor={theme.colors.secondary.hex()} offset='1' />
        </radialGradient>
        <linearGradient
          id='linearGradient2898'
          x1='78.727'
          x2='75.015'
          y1='165.39'
          y2='160.53'
          gradientTransform='translate(5.0781 .53454)'
          gradientUnits='userSpaceOnUse'
          spreadMethod='reflect'
        >
          <stop offset='0' />
          <stop stopColor={theme.colors.light.hex()} offset='1' />
        </linearGradient>
        <linearGradient
          id='linearGradient2949'
          x1='86.56'
          x2='83.076'
          y1='160.52'
          y2='156.72'
          gradientTransform='matrix(.16611 -.80649 .66874 .2359 -19.312 147.05)'
          gradientUnits='userSpaceOnUse'
          spreadMethod='reflect'
        >
          <stop offset='0' />
          <stop stopColor={theme.colors.light.hex()} offset='1' />
        </linearGradient>
        <linearGradient
          id='linearGradient2978'
          x1='75.058'
          x2='81.017'
          y1='160.58'
          y2='167.42'
          gradientTransform='rotate(163.28 103.79 141.72)'
          gradientUnits='userSpaceOnUse'
          spreadMethod='reflect'
        >
          <stop offset='0' />
          <stop stopColor={theme.colors.light.hex()} offset='1' />
        </linearGradient>
        <linearGradient
          id='linearGradient3012'
          x1='73.524'
          x2='77.924'
          y1='160.57'
          y2='167.3'
          gradientTransform='rotate(90.906 100.1 195.16)'
          gradientUnits='userSpaceOnUse'
          spreadMethod='reflect'
        >
          <stop offset='0' />
          <stop stopColor={theme.colors.secondary.hex()} offset='1' />
        </linearGradient>
      </defs>
      <g
        transform='translate(-47.722 -84.117)'
        style={{
          fillRule: 'evenodd'
        }}
      >
        <path
          d='m107.34 109.9a10 9.99 0 1 1-20 0 10 9.99 0 1 1 20 0z'
          style={{
            fill: 'url(#radialGradient6459-5)'
          }}
        />
        <path
          d='m155.16 188.79a10 9.99 0 1 1-20 0 10 9.99 0 1 1 20 0z'
          style={{
            fill: 'url(#radialGradient6459-5-7-8)'
          }}
        />
      </g>
      <g
        transform='translate(-47.722 -84.117)'
        style={{
          strokeOpacity: 0
        }}
      >
        <path
          d='m98.609 119.93-1.8594-4.3254c-0.08653-2.0468 2.7396-3.4321 4.4841-2.0625l1.8359 4.9241c-1.7637 0.58275-2.6969 0.88103-4.4606 1.4638z'
          style={{
            fill: 'url(#linearGradient2949)'
          }}
        />
        <path
          d='m120.97 152 21.187 27.913c0.02 1.4111-3.9111 4.7652-5.3529 4.66l-22.239-27.97c2.3131-1.3564 4.0917-3.2473 6.4048-4.6037z'
          style={{
            fill: 'url(#linearGradient3012)'
          }}
        />
      </g>
      <g transform='translate(-47.722 -84.117)'>
        <path
          d='m133.58 141.39a25 24.991 0 1 1-50 0 25 24.991 0 1 1 50 0z'
          fill='url(#radialGradient6459)'
          style={{
            fill: 'url(#radialGradient6459)',
            fillRule: 'evenodd'
          }}
        />
      </g>
      <g
        transform='translate(-47.722 -84.117)'
        style={{
          strokeOpacity: 0
        }}
      >
        <path
          d='m68.621 169.92 20.647-16.036c2.8746-0.43297 5.0975 1.9599 4.744 5.2786l-21.212 17.632c-1.3928-2.2914-2.7856-4.5827-4.1783-6.8741z'
          style={{
            fill: 'url(#linearGradient2898)'
          }}
        />
        <path
          d='m133.53 102.81-14.311 22.148c-1.523 0.79874-6.1092-0.3866-5.3532-3.6909l14.347-22.61c1.993 1.7939 3.3246 2.3594 5.3175 4.1533z'
          style={{
            fill: 'url(#linearGradient2978)'
          }}
        />
      </g>
      <g transform='translate(-47.722 -84.117)'>
        <g transform='translate(-5.9051 10.978)'>
          <path
            d='m149.71 83.674a10 9.99 0 1 1-20 0 10 9.99 0 1 1 20 0z'
            fill='url(#radialGradient6459-5-7)'
            style={{
              fill: 'url(#radialGradient6459-5-7)',
              fillRule: 'evenodd'
            }}
          />
        </g>
        <path
          d='m72.8 176.79a10 9.99 0 1 1-20 0 10 9.99 0 1 1 20 0z'
          fill='url(#radialGradient6459-5-7-6)'
          style={{
            fill: 'url(#radialGradient6459-5-7-6)',
            fillRule: 'evenodd'
          }}
        />
      </g>
    </svg>
  )
);
