import { Num } from '@splotch/core-utils';
import React, { useContext } from 'react';
import { Form, Popover } from 'react-bootstrap';
import { FixedWidthPopover } from '../_.styled';
import { SpectrumContext } from '../spectrum-store';

export const Phasing = (): React.ReactElement => {
  const [
    { zeroOrderPhaseCorrection, firstOrderPhaseCorrection },
    { setZeroOrderPhaseCorrection, setFirstOrderPhaseCorrection }
  ] = useContext(SpectrumContext);

  return (
    <>
      <Popover.Title as='h2'>Choose Phase Constants</Popover.Title>
      <FixedWidthPopover>
        <Form.Label>Zero Order: {zeroOrderPhaseCorrection}</Form.Label>
        <Form.Control
          type='range'
          min='-360'
          max='360'
          step='.1'
          value={zeroOrderPhaseCorrection}
          onChange={(e): void => {
            setZeroOrderPhaseCorrection!(Num.normalize(e.target.value)!);
          }}
        />
        <Form.Label className='mt-3'>First Order: {firstOrderPhaseCorrection}</Form.Label>
        <Form.Control
          type='range'
          min='-360'
          max='360'
          step='.1'
          value={firstOrderPhaseCorrection}
          onChange={(e): void => {
            setFirstOrderPhaseCorrection!(Num.normalize(e.target.value)!);
          }}
        />
      </FixedWidthPopover>
    </>
  );
};
