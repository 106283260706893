import { standardDeviation } from 'ml-stat/array';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace StandardDeviation {
  export const id = 'standardDeviation';
  export const name = 'Standard Deviation';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !datum.info.isFid;

  export const reduce = (): Filter.ReduceResult => ({
    once: false
  });

  export const apply = (datum: Spectrum1D.Datum): void => {
    if (!isApplicable(datum)) {
      throw new Error('Standard Deviation is not applicable on this data');
    }

    const re = [...datum.data.re];
    const im = datum.data.im ? [...datum.data.im] : undefined;

    const reStd = standardDeviation(re);
    const imStd = im ? standardDeviation(im) : undefined;

    for (let i = 0; i < re.length; i++) {
      re[i] /= reStd;

      if (im && imStd) {
        im[i] /= imStd;
      }
    }

    datum.data.re = Float64Array.from(re);

    if (im) {
      datum.data.im = Float64Array.from(im);
    }
  };
}
