import { AutoRepeat as AutoRepeat_ } from './auto-repeat';
import { Grid } from './grid';
import { Item as Item_ } from './item';
import { KVPairs as KVPairs_ } from './kv-pairs';
import { Symmetric as Symmetric_ } from './symmetric';

/* eslint-disable */
declare module './grid' {
  export namespace Grid {
    export import AutoRepeat = AutoRepeat_;
    export import Item = Item_;
    export import KVPairs = KVPairs_;
    export import Symmetric = Symmetric_;
  }
}
/* eslint-enable */

Object.assign(Grid, {
  AutoRepeat: AutoRepeat_,
  Item: Item_,
  KVPairs: KVPairs_,
  Symmetric: Symmetric_
});

export { Grid };
