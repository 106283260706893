import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace ShiftX {
  export const id = 'shiftX';
  export const name = 'Shift X';

  export const isApplicable = (_datum: Spectrum1D.Datum): boolean => true;

  export const reduce = (previousValue: number, newValue: number): Filter.ReduceResult => ({
    once: true,
    reduce: previousValue + newValue
  });

  export const apply = (datum: Spectrum1D.Datum, shiftValue: number = 0): void => {
    if (!isApplicable(datum)) {
      throw new Error('shiftX not applicable on this data');
    }

    datum.data.x = datum.data.x.map((val) => val + shiftValue);
  };
}
