import { Obj } from './obj';

export interface ComplexNumber {
  real: number;
  imag: number;
}

export namespace ComplexNumber {
  export const normalize = (value: unknown): ComplexNumber | undefined =>
    Obj.normalize<ComplexNumber>(value);
}
