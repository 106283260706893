import { v4 } from 'uuid';
import validator from 'validator';
import { Str } from './str';

export type UUID = string;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace UUID {
  export const PREFIX_LENGTH = 7;
  export const TOTAL_LENGTH = 36;

  export const prefix = (uuid: UUID): string => uuid.slice(0, PREFIX_LENGTH);
  export const make = (): UUID => v4();

  export const normalize = (value: unknown): UUID | undefined => {
    const stringVal = Str.normalize(value);

    return stringVal && validator.isUUID(stringVal) ? stringVal.toLowerCase() : undefined;
  };

  export const isValid = (uuid?: unknown): boolean => !!normalize(uuid);
}
