import React, { Children } from 'react';
import type { ReactElement } from 'react';
import { Grid } from './grid';

export const Symmetric = ({ children, ...props }: Symmetric.Props): ReactElement => (
  <Grid gridTemplateColumns={`repeat(${Children.toArray(children).length}, 1fr)`} {...props}>
    {children}
  </Grid>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Symmetric {
  export type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;
}
