import { Grid } from '@splotch/core-ui';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Footer, InfoPageWrapper } from '../../components';
import { Privacy } from './privacy';
import { Terms } from './terms';

export const LegalRoute = (): React.ReactElement => {
  const { document } = useParams<{ document: string }>();

  return (
    <InfoPageWrapper>
      <div className='info-content flex-grow-1'>
        <Grid gridTemplateColumns='10rem 1fr' className='mt-3'>
          <div>
            <p className='m-0'>
              {document === 'privacy' ? (
                '> Privacy Policy'
              ) : (
                <Link to='/legal/privacy'>Privacy Policy</Link>
              )}
            </p>
            <p className='m-0'>
              {document === 'terms' ? (
                '> Terms of Service'
              ) : (
                <Link to='/legal/terms'>Terms of Service</Link>
              )}
            </p>
          </div>
          {document === 'privacy' ? <Privacy /> : document === 'terms' ? <Terms /> : null}
        </Grid>
      </div>
      <Footer />
    </InfoPageWrapper>
  );
};
