import React from 'react';
import { ContactForm, Footer, InfoPageWrapper } from '../components';

export const BugReportRoute = (): React.ReactElement => (
  <InfoPageWrapper>
    <div className='info-content flex-grow-1'>
      <ContactForm title='Report a Bug' subjectPrefix='Bug Report: ' />
    </div>
    <Footer />
  </InfoPageWrapper>
);
