import { Bool } from './bool';
import { Str } from './str';
import type { Any } from './types';

/* eslint-disable node/no-process-env */

const NODE_ENV = Str.normalize(process.env.NODE_ENV);

const isProduction = NODE_ENV && ['production', 'prod'].includes(NODE_ENV.toLowerCase());

export const Env = Object.freeze({
  NODE_ENV,
  isProduction,
  isDevelopment: !isProduction,
  isTesting:
    (NODE_ENV && ['testing', 'test'].includes(NODE_ENV.toLowerCase())) /* istanbul ignore next */ ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof (<Any>globalThis).describe === 'function',
  isDebug: Bool.normalize(process.env.DEBUG),

  missing(key: string): never {
    throw new Error(`Missing required environment variable: ${key}`);
  }
});
