import _ from 'lodash';
import { Nix } from './nix';
import { Num } from './num';
import { Scalar } from './scalar';

export namespace Bool {
  /**
   * Indicates if the specified value is "falsey". A value is considered falsey if it is:
   *
   * - The literal `null` or `undefined`
   * - The literal boolean `false` or `Boolean(false)`
   * - A numeric value (or a numeric string equivalent) with a value of zero
   * - A string with a literal value (case insentitive) of: `0`, `f`, `false`, `n`, `no`, `off`
   * - An empty string or string with only spaces
   * - An empty array (actually any array)
   *
   * Notes that the input value is first converted to a scalar (see `Scalar.normalize`).
   *
   * @param value The value to be queried.
   * @returns `true` if the value is falsey, `false` otherwise.
   */
  export const isFalsey = (value: unknown): boolean => {
    const scalarVal = Scalar.normalize(value);

    if (Nix.isNil(scalarVal) || scalarVal === false) {
      return true;
    }

    let booleanVal = false;

    // eslint-disable-next-line you-dont-need-lodash-underscore/is-string
    if (_.isString(scalarVal)) {
      const stringVal: string = _.toString(scalarVal).toLowerCase().trim();

      if (
        !stringVal ||
        ['0', 'f', 'false', 'n', 'no', 'off'].includes(stringVal) ||
        Num.normalize(stringVal) === 0 // Numeric-like string value
      ) {
        booleanVal = true;
      }
    } else if (typeof scalarVal === 'number') {
      booleanVal = Number.isFinite(scalarVal) ? !scalarVal : true;
    }

    return booleanVal;
  };

  /**
   * Indicates if the specified value is "truthy". A value is considered truthy if it is:
   *
   * - The literal `true` or `Boolean(true)`
   * - A numeric value (or a numeric string equivalent) with a non-zero value.
   * - A string with a literal value (case insentitive) of: `1`, `on`, `t`, `true`, `y`, `yes`
   *
   * Notes that the input value is first converted to a scalar (see `Scalar.normalize`).
   *
   * @param value The value to be queried.
   * @returns `true` if the value is truthy, `false` otherwise.
   */
  export const isTruthy = (value: unknown): boolean => {
    const scalarVal = Scalar.normalize(value);

    let booleanVal = false;

    if (scalarVal) {
      // istanbul ignore else
      if (scalarVal === true) {
        booleanVal = true;
      } else if (typeof scalarVal === 'string') {
        const stringVal = scalarVal.toLowerCase().trim();

        if (['1', 'on', 't', 'true', 'y', 'yes'].includes(stringVal)) {
          booleanVal = true;
        } else {
          const numberVal = Num.normalize(scalarVal); // Numeric-like string value

          booleanVal = !Nix.isNil(numberVal) && numberVal !== 0;
        }
      } else if (typeof scalarVal === 'number') {
        booleanVal = Number.isFinite(scalarVal) ? !!scalarVal : false;
      }
    }

    return booleanVal;
  };

  /**
   * Converts the specified value to a boolean.
   *
   * @see isTruthy
   */
  export const normalize = isTruthy;
}
