import type { Any } from '@splotch/core-utils';
import { Nix } from '@splotch/core-utils';
import type { Spectrum1D } from '../model';
import { Filter } from '../model';
import { generateID } from './generate-id';

export namespace FilterManager {
    export const isLastFilter = (datum: Spectrum1D.Datum, id: string): boolean => {
        const index = datum.filters.findIndex((f) => f.name === id);

        if (datum.filters.length === index + 1) {
            return true;
        }

        return false;
    };

    // @fixme: This should reference the filter `id`, not `name`
    export const lookupForFilter = (
        datum: Spectrum1D.Datum,
        filterName: string
    ): Filter | undefined => datum.filters.find((f) => f.name === filterName);

    export const enableFilter = (
        datum: Spectrum1D.Datum,
        id?: string,
        checked?: boolean,
        filters?: Filter[]
    ): void => {
        if (id) {
            datum.filters = datum.filters.map(
                (filter) => ({ ...filter, flag: filter.id === id ? checked : filter.flag }),
                []
            );
        }
        datum.data = { ...datum.data, ...datum.originalData };
        datum.info = { ...datum.info, ...datum.originalInfo };

        (filters ?? datum.filters).forEach((filter): void => {
            filter.error = undefined;

            if (filter.flag && filter.name && filter.name in Filter) {
                try {
                    Filter.getFilterFunction(filter.name)?.apply(datum, filter.value);
                } catch (error: unknown) {
                    filter.error = (<Error>error).message;

                }
            }
        });

    };

    export const reapplyFilters = (datum: Spectrum1D.Datum, filters?: Filter[]): void => {
        enableFilter(datum, undefined, undefined, filters);
    };

    export const deleteFilter = (datum: Spectrum1D.Datum, id: string): void => {
        datum.filters = [...datum.filters];
        datum.filters = datum.filters.filter((filter) => filter.id !== id);
        datum.data = { ...datum.data, ...datum.originalData };
        datum.info = { ...datum.info, ...datum.originalInfo };

        datum.filters.forEach((filter) => {
            filter.error = undefined;

            if (filter.flag) {
                try {
                    Filter.getFilterFunction(filter.name)?.apply(datum, filter.value);
                } catch (error: unknown) {
                    filter.error = (<Error>error).message;
                }
            }
        });
    };

    export const addFilter = (
        datum: Spectrum1D.Datum,
        filter: Filter,
        isDeleteAllow: boolean = true
    ): void => {
        const id = generateID();

        datum.filters = [...datum.filters];
        datum.filters.push({
            ...filter,
            id,
            flag: true,
            isDeleteAllow
        });
    };

    export const replaceFilter = (datum: Spectrum1D.Datum, filterID: string, value: Any): void => {
        const filter = datum.filters.find(f => f.id === filterID);

        if (filter) {
            // @fixme: value should not be of type <Any>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            filter.value = value;
        }
    };

    export const applyFilter = (datum: Spectrum1D.Datum, filters: Filter[]): void => {
        let isReduced = false;

        for (const filter of filters) {
            const filterOption = {
                id: filter.id,
                name: filter.name,
                label: Filter.getFilterFunction(filter.name)?.name ?? filter.name,
                isDeleteAllow: filter.isDeleteAllow,
                // @fixme: filter.options should not be of type <Any>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value: filter.options
            };
            const previousFilter = lookupForFilter(datum, filter.name);

            if (previousFilter) {
                const reduceResult = Filter.getFilterFunction(filter.name)?.reduce(previousFilter.value, filterOption.value);

                if (reduceResult?.once) {
                    if (!isReduced) {
                        isReduced = true;
                    }
                    if (Nix.isNotNil(reduceResult.reduce)) {
                        replaceFilter(datum, previousFilter.id, reduceResult.reduce);
                    }
                } else {
                    addFilter(
                        datum,
                        filterOption,
                        Object.prototype.hasOwnProperty.call(filter, 'isDeleteAllow')
                            ? filter.isDeleteAllow
                            : true
                    );
                }
            } else {
                addFilter(
                    datum,
                    filterOption,
                    Object.prototype.hasOwnProperty.call(filter, 'isDeleteAllow')
                        ? filter.isDeleteAllow
                        : true
                );
            }
        }
        if (isReduced) {
            if (filters.length === 1 && isLastFilter(datum, filters[0].name)) {
                Filter.getFilterFunction(filters[0].name)?.apply(datum, filters[0].options);
            } else {
                reapplyFilters(datum);
            }
        } else {
            for (const filter of filters) {
                Filter.getFilterFunction(filter.name)?.apply(datum, filter.options);
            }
        }
    };
}
