interface Point {
  x: number;
  y: number;
}

interface Range {
  xMin: number;
  xMax: number;
}

export const floategral = (data: Point[]): number => {
  if (data.length < 2) {
    return 0.0;
  }

  let result = 0.0;

  for (let i = 0; i < data.length - 1; i++) {
    const coord1 = data[i];
    const coord2 = data[i + 1];

    // Formula for getting area under a curve
    const m = (coord2.y - coord1.y) / (coord2.x - coord1.x);
    const b = coord1.y - m * coord1.x;

    const combinedArea =
      (m * coord2.x ** 2) / 2 + b * coord2.x - ((m * coord1.x ** 2) / 2 + b * coord1.x);

    result += combinedArea;
  }

  return result;
};

export const getDataSubset = (data: Point[], x1: number, x2: number): Point[] => {
  const xMin = Math.min(x1, x2);
  const xMax = Math.max(x1, x2);

  return data.filter(({ x }) => x >= xMin && x <= xMax);
};

export const getFloategrals = (data: Point[], ranges: Range[]): number[] => {
  const floategrals: number[] = [];

  ranges.forEach((range) => {
    const subset = getDataSubset(data, range.xMin, range.xMax);
    const tmp = floategral(subset);

    floategrals.push(tmp);
  });

  return floategrals;
};

export const rationalize = (arr: number[]): number[] => {
  const min = Math.min(...arr);

  return arr.map((i) => Number.parseFloat((i / min).toFixed(4)));
};
