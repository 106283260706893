import React from 'react';
import { Link } from 'react-router-dom';
import { SOCIAL_MEDIA_LINKS } from '../../constants';
import { Icons } from '../svg';
import { StyledFooter } from './__styled';

export const Footer = (): React.ReactElement => (
  <StyledFooter fluid className='bg-dark text-light'>
    <div className='footer-content'>
      <ul className='footer-contact-links'>
        {SOCIAL_MEDIA_LINKS.map((link) => (
          <li key={link.to}>
            <a href={link.to} className='text-light'>
              {link.icon}
            </a>
          </li>
        ))}
      </ul>
      <div style={{ fontSize: '32px' }}>
        SPL
        <Icons.MethaneLogo size={64} style={{ margin: '-5px -13px 0px' }} />
        TCH
      </div>
      <div className='py-2'>Copyright © Splotch 2021</div>
      <div className='py-2'>
        <Link className='pr-2 text-light' to='/legal/terms'>
          Terms of Service
        </Link>
        |
        <Link to='/legal/privacy' className='ml-2 text-light'>
          Privacy Policy
        </Link>
      </div>
    </div>
  </StyledFooter>
);
