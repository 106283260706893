import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledFooter = styled(Container)`
  padding: 1rem;
  font-size: 0.8em;

  .footer-content {
    width: var(--max-page-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-contact-links {
      list-style: none;
      padding: 0;
      margin: 16px 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 18px;

      > li {
        margin: 0 1em;
      }

      ::before {
        position: absolute;
        width: 33%;
        right: 0;
        content: '';
        border-top: 1px solid var(--light);
        top: 50%;
      }

      ::after {
        position: absolute;
        width: 33%;
        left: 0;
        content: '';
        border-top: 1px solid var(--light);
        top: 50%;
      }
    }
  }
`;
