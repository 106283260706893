import { Enum } from '@splotch/core-utils';
import { loadAsync as unzip } from 'jszip';
import { FileUtils } from '../file-utils';

// @fixme: Fix the typing on these props
interface Props {
  files: File[];
}

export const loadZip = async ({ files: zipFiles }: Props): Promise<FileUtils.LoadedFile[]> => {
  const loadedFiles = await FileUtils.loadFiles(zipFiles, {
    asBuffer: true
  });

  return Promise.all(
    loadedFiles.map(async (loadedFile) => {
      const { files: unzippedFiles } = await unzip(loadedFile.binary);

      const uniqueExtensions = [
        ...new Set(
          Object.values(unzippedFiles).map((file) => FileUtils.getFileExtension(file.name))
        )
      ];
      const hasAdditionalFileTypes = uniqueExtensions.some(
        (ex) => Enum.normalize(FileUtils.NmrFileTypes, ex) && ex !== FileUtils.NmrFileTypes.ZIP
      );

      // @fixme - Load sub files from archive
      if (hasAdditionalFileTypes) {
        // loadsubFilesfromZip(Object.values(unzipResult.files), uniqueZipArchiveExtensions);
      }

      return loadedFile;
    })
  );
};
