import { Nix, Num, Str } from '@splotch/core-utils';

export type Length = string | 0;

const fromNumber = (value: number): Length => (value !== 0 ? `${value}px` : 0);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Length {
  export const normalize = (value: unknown): Length | undefined => {
    let length;

    if (Nix.isNotNil(value)) {
      // Were we handed a number?
      if (typeof value === 'number') {
        length = fromNumber(value);
      } else {
        const strValue = Str.normalize(value);

        // Were we handed a string?
        if (strValue) {
          // Was this a number-like string?
          const numValue = Num.normalize(strValue);

          length = Nix.isNotNil(numValue) ? fromNumber(numValue) : strValue;
        }
      }
    }

    return length;
  };
}
