import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Theme } from '@splotch/core-ui';
import Color from 'color';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import type { DropEvent, FileRejection } from 'react-dropzone';
import styled, { css } from 'styled-components';
import type { SimpleInterpolation } from 'styled-components';

const StyledDropzone = styled.div`
  ${({ theme }: { theme: Theme }): SimpleInterpolation => css`
    background-color: ${Color(theme.colors.white).alpha(0.75).toString()};
    border: 2px solid ${Color(theme.colors.primary).alpha(0.25).toString()};
  `}
  padding: 1rem;

  p {
    font-size: 18px;
  }
`;

interface Props {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  activeText: string;
  inactiveText: string;
}

export const FileDropzone = ({ onDrop, activeText, inactiveText }: Props): React.ReactElement => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input type='file' {...getInputProps()} />
      <StyledDropzone className='rounded'>
        <p className='text-muted font-weight-bold'>{isDragActive ? activeText : inactiveText}</p>
        <FontAwesomeIcon className='text-muted' icon={faCloudUploadAlt} size='2x' />
      </StyledDropzone>
    </div>
  );
};
