import React from 'react';

const DEFAULT_SIZE = 16;
const DEFAULT_COLOR = 'rgb(202, 153, 18)';
const DEFAULT_SECONDARY_COLOR = 'rgb(0, 160, 0)';

type Props = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
  secondaryColor?: string;
};

export const MultiplicityIcon = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
  secondaryColor = DEFAULT_SECONDARY_COLOR
}: Props): React.ReactElement => (
  <svg width={size} height={size} viewBox='0 0 98.465 99.069'>
    <g transform='translate(-61.214 -72.747)' fill='none'>
      <path
        d='m75.846 170.43s7.5056-35.419 12.572-35.448c5.2219-0.03 1.5535 16.376 7.6255 16.694 6.4962 0.33967 6.4752-34.144 13.396-34.212 6.4132-0.0624 3.6937 34.129 11.335 34.212 6.1335 0.066 3.8138-17.108 9.2742-16.487 5.0028 0.56893 10.717 35.242 10.717 35.242'
        stroke={color}
        style={{ strokeWidth: '7.5' }}
      />
      <path
        d='m72.169 83.402v29.123-14.859h70.387v15.602-29.123'
        stroke={secondaryColor}
        style={{ strokeWidth: '10' }}
      />
    </g>
  </svg>
);
