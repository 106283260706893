import type { Environment } from '@splotch/labs/frontend';
import qs from 'qs';
import { environment as pro } from './environment.pro';

export const environment: Readonly<Environment> =
  qs.parse(window.location.search, { ignoreQueryPrefix: true }).env === 'pro'
    ? pro
    : Object.freeze({
        production: false,
        SENTRY_DSN: 'https://8d9d17e8f2534ae1819407b95d860c86@o506959.ingest.sentry.io/5597363',
        GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-192617838-1'
      });
