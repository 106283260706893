import { Theme } from '@splotch/core-ui';
import React from 'react';
import type { PropsWithChildren, ReactElement } from 'react';

const loaders: Record<Theme.Name, Theme.Provider.Loader> = {
  base: {
    component: async () => import(/* webpackChunkName: "base-theme" */ './base')
  }
};

export const Provider = ({ children, ...rest }: Provider.Props): ReactElement => (
  <Theme.Provider loaders={loaders} {...rest}>
    {children}
  </Theme.Provider>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Provider {
  export type Props = PropsWithChildren<{ themeName?: Theme.Name }>;
}
