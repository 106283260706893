import cx from 'classnames';
import React from 'react';
import { Delay } from './delay';
import { Gears } from './gears';

export const Loading = ({ className, ...props }: Loading.Props): React.ReactElement => (
  <div className={cx('d-grid', 'place-items-center', 'w-100', 'h-100', className)}>
    <Gears {...props} /> <span className='sr-only'>Loading...</span>
  </div>
);

Loading.Delay = Object.assign(
  ({ delay, ...props }: Loading.Props & Pick<Delay.Props, 'delay'>): React.ReactElement => (
    <Delay delay={delay}>
      <Loading {...props} />
    </Delay>
  ),
  { displayName: 'Loading.Delay' }
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Loading {
  export type Props = Gears.Props & { className?: string };
}
