import { assert } from '@sindresorhus/is';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace LineBroadening {
  export const id = 'lineBroadening';
  export const name = 'Line broadening';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !!datum.info.isFid;

  export const reduce = (previousValue: number, newValue: number): Filter.ReduceResult => ({
    once: true,
    reduce: previousValue + newValue
  });

  export const apply = (datum: Spectrum1D.Datum, value: number): void => {
    if (!isApplicable(datum)) {
      throw new Error('lineBroadening not applicable on this data');
    }

    const { digitalFilter: grpdly } = datum.info;

    // fixme: This should not be optional
    assert.number(grpdly);

    const pointsToShift = grpdly > 0 ? Math.floor(grpdly) : 0;

    const { re } = datum.data;
    const { im } = datum.data;
    const t = datum.data.x;

    const { length } = re;

    const newRE = new Float64Array(length); // I don't think we need a new array... here
    const newIM = new Float64Array(length);
    /*
     * if (value !== 0) {// is it OK to skip this line if "value" is zero?
     * please check this test of zero is correct !== or != ...
     */
    const dw = (t[length - 1] - t[0]) / (length - 1); // REPLACE CONSTANT with calculated value... : for this we need AQ or DW to set it right...
    // convert line broadening in Hz into exponential coefficient:
    const em = -value * Math.exp(1);

    const coefExp = Math.exp(em * dw);
    let curFactor = Math.exp(em * t[0]); // in case does not start at zero

    for (let i = 0; i < length - pointsToShift; i++) {
      newRE[i] = re[i] * curFactor;

      if (im) {
        newIM[i] = im[i] * curFactor;
      }

      curFactor *= coefExp;
    }
    curFactor = Math.exp(em * t[0]);
    for (let i = length; i > length - pointsToShift; i--) {
      newRE[i] = re[i] * curFactor;

      if (im) {
        newIM[i] = im[i] * curFactor;
      }

      curFactor *= coefExp;
    }
    datum.data = { ...datum.data, ...{ re: newRE, im: newIM } };
  };
}
