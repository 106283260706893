import { useCallback, useContext, useState } from 'react';
import type { Spectrum1D } from '../../model';
import { Context } from './context';

interface Response {
  addSpectra: (_spectra: Spectrum1D.Datum[]) => void;
  loading: boolean;
  error?: Error;
}

export namespace UseAddSpectra {
  export const useAddSpectra = (): Response => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const { setDatum } = useContext(Context);

    const addSpectra = useCallback(
      (spectra: Spectrum1D.Datum[]) => {
        setLoading(true);
        // const spectra = [];

        try {
          setDatum!(spectra[0]);
        } catch (_error: unknown) {
          setError(<Error>_error);
        }
        setLoading(false);

        // return spectra;
      },
      [setDatum]
    );

    return {
      addSpectra,
      error,
      loading
    };
  };
}
