import React from 'react';
import { Link } from 'react-router-dom';

export const Privacy = (): React.ReactElement => (
  <div>
    <h2>Privacy Policy</h2>

    <p className='font-weight-bold'>Last Updated: March 26th, 2021</p>

    <p>
      Splotch exists to help streamline the scientific research process. We build community-driven
      applications whose foundations are built on trust. A fundamental part of earning that trust
      means being clear about how we use your information and protect your human right to privacy.
    </p>

    <p>
      We only ask for personal information when we truly need it to provide a service to you. We
      collect it by fair and lawful means, with your knowledge and consent. We also let you know why
      we’re collecting it and how it will be used.
    </p>

    <p>
      We only retain collected information for as long as necessary to provide you with your
      requested service. What data we store, we’ll protect within commercially acceptable means to
      prevent loss and theft, as well as unauthorized access, disclosure, copying, use or
      modification. We don’t share any personally identifying information publicly or with
      third-parties, except when required to by law.
    </p>

    <p>
      We use cookies to analyze user activity in order to improve the Site. To this end, we utilize
      Google Analytics to gather anonymized information in order to look at aggregate patterns, such
      as the average number of uploads that users perform and how many users visit the Site. We can
      use such analysis to gain insights about how to improve the functionality and user experience
      of the Site.
    </p>

    <p>
      Our app may link to external sites that are not operated by us. Please be aware that we have
      no control over the content and practices of these sites, and cannot accept responsibility or
      liability for their respective privacy policies.
    </p>

    <p>
      You are free to refuse our request for your personal information, with the understanding that
      we may be unable to provide you with some of your desired services. Your continued use of our
      app will be regarded as acceptance of our practices around privacy and personal information.
      If you have any questions about how we handle user data and personal information, feel free to
      contact us at <Link to='mailto:support@splotch.io'>support@splotch.io</Link>.
    </p>
  </div>
);
