import React, { useCallback, useState } from 'react';
import { GOOGLE_FORM_URL } from '../../constants';

interface Props {
  title?: string;
  subjectPrefix?: string;
}

export const ContactForm = ({
  title = 'Email Us',
  subjectPrefix = ''
}: Props): React.ReactElement => {
  const [hiddenFrameLoaded, setHiddenFrameLoaded] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(subjectPrefix);
  const [message, setMessage] = useState('');

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };
  const onChangeSubject = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSubject(e.target.value);
  };
  const onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setMessage(e.target.value);
  };

  const isSubmitButtonEnabled = useCallback(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return name.length > 0 && emailRegex.test(email) && subject && message;
  }, [name, email, subject, message]);

  const handleHiddenFrameLoaded = useCallback(() => {
    if (hiddenFrameLoaded) {
      setSubmittedForm(true);
    } else {
      setHiddenFrameLoaded(true);
    }
  }, [hiddenFrameLoaded]);

  return (
    <section id='contact-form'>
      <div className='container'>
        <iframe
          title='hidden_iframe'
          name='hidden_iframe'
          id='hidden_iframe'
          style={{ display: 'none' }}
          onLoad={handleHiddenFrameLoaded}
        />

        <div className='section-title'>
          <h2 className='py-3 m-0'>{title}</h2>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            {submittedForm ? (
              <div className='card'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    Thank you for your message! We will be in touch soon.
                  </h5>
                  <h6 className='text-muted'>- The Splotch Crew</h6>
                </div>
              </div>
            ) : (
              <form
                target='hidden_iframe'
                action={GOOGLE_FORM_URL}
                method='post'
                className='contact-form row'
              >
                <div className='col-md-6'>
                  <input
                    onChange={onChangeName}
                    type='text'
                    name='entry.614435459'
                    className='form-control'
                    id='name'
                    placeholder='Your Name'
                  />
                  <p className='input-required'>* required</p>
                </div>
                <div className='col-md-6'>
                  <input
                    onChange={onChangeEmail}
                    type='email'
                    className='form-control'
                    name='entry.227748428'
                    id='email'
                    placeholder='Your Email'
                  />
                  <p className='input-required'>* required</p>
                </div>
                <div className='col-12'>
                  <input
                    onChange={onChangeSubject}
                    type='text'
                    className='form-control'
                    name='entry.434486724'
                    id='subject'
                    placeholder='Subject'
                    value={subject}
                  />
                  <p className='input-required'>* required</p>
                </div>
                <div className='col-12'>
                  <textarea
                    onChange={onChangeMessage}
                    className='form-control'
                    name='entry.1997604375'
                    rows={5}
                    placeholder='Message'
                  />
                  <p className='input-required'>* required</p>
                </div>
                <div className='text-center col-12'>
                  <button
                    type='submit'
                    className={`btn btn-primary${!isSubmitButtonEnabled() ? ' disabled' : ''}`}
                    disabled={!isSubmitButtonEnabled()}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
