import React, { useMemo, useState } from 'react';
import type { Spectrum1D } from '../../model';
import type { Store } from './context';
import { Context } from './context';

export const Provider = ({ children }: React.PropsWithChildren<unknown>): React.ReactElement => {
  const [datum, setDatum] = useState<Spectrum1D.Datum | undefined>();

  const value = useMemo((): Store => ({ datum, setDatum }), [datum, setDatum]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
