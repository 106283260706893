import type { FromBrukerResult } from 'nmr-parser';
import type { Spectrum1D } from '../../model';
import type { UsedColors } from '../get-color';
// eslint-disable-next-line import/no-internal-modules
import { initiateDatum1D } from '../spectra/initiate-datum-1d';

interface Component {
  data?: {
    x?: Float64Array;
    y?: Float64Array;
  };
}

interface Options {
  xy?: boolean;
  noContours?: boolean;
  keepOriginal?: boolean;
  display?: {
    name?: string;
  };
}

const getData = (spectra: Component[]): Spectrum1D.Data => {
  const x = spectra[0]?.data?.x ?? new Float64Array();
  const re = spectra[0]?.data?.y ?? new Float64Array();
  const im = spectra[1]?.data?.y ?? new Float64Array();

  if (x[0] > x[1]) {
    x.reverse();
    re.reverse();
    im.reverse();
  }

  return { x, re, im };
};

export const fromBruker = (
  result: FromBrukerResult,
  options: Options = {},
  usedColors: UsedColors = {}
): Spectrum1D.Datum => {
  const { dependentVariables, meta, source } = result;
  // eslint-disable-next-line prefer-destructuring
  const info: Partial<Spectrum1D.Info> = result.info;
  const data = getData(<Component[]>dependentVariables[0].components);

  if (data.im) {
    info.isComplex = true;
  }

  if (Array.isArray(info.nucleus)) {
    [info.nucleus] = info.nucleus;
  }

  const datum1D = initiateDatum1D(
    {
      ...options,
      source,
      info,
      meta,
      data
    },
    usedColors
  );

  return datum1D;
};
