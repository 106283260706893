import React from 'react';

const DEFAULT_SIZE = 16;
const DEFAULT_COLOR = 'rgb(202, 153, 18)';
const DEFAULT_SECONDARY_COLOR = 'rgb(0, 160, 0)';

type Props = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
  secondaryColor?: string;
};

export const PhasingIcon = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
  secondaryColor = DEFAULT_SECONDARY_COLOR
}: Props): React.ReactElement => (
  <svg width={size} height={size} viewBox='0 0 98.465 99.069'>
    <defs>
      <marker id='Arrow2Send' overflow='visible' orient='auto'>
        <path
          transform='matrix(-.3 0 0 -.3 .69 0)'
          d='m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z'
          fill={secondaryColor}
          stroke={secondaryColor}
          style={{
            fillRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeWidth: '.625'
          }}
        />
      </marker>
    </defs>
    <g transform='translate(-61.214 -72.747)' fill='none'>
      <path
        d='m62.081 123.94s28.914-50.329 36.249-50.195c6.7802 0.12415 17.792 97.086 25.528 97.069 7.5725-0.0171 34.949-48.725 34.949-48.725'
        stroke={color}
        style={{ strokeWidth: '7.5' }}
      />
      <path
        d='m127.01 144.97v-33.722'
        stroke={secondaryColor}
        style={{ strokeWidth: '10', markerEnd: 'url(#Arrow2Send)' }}
      />
    </g>
  </svg>
);
