import React from 'react';

const DEFAULT_SIZE = 28;
const DEFAULT_COLOR = '#c70000';
const DEFAULT_COLOR_GRADIENT = '#ffbebe';
const DEFAULT_AXIS_COLOR = '#ff0000';
const DEFAULT_CURVE_COLOR = '#000000';

type Props = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
  colorGradient?: string;
  curveColor?: string;
  axisColor?: string;
};

export const MagneticLogo = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
  colorGradient = DEFAULT_COLOR_GRADIENT,
  curveColor = DEFAULT_CURVE_COLOR,
  axisColor = DEFAULT_AXIS_COLOR,
  ...rest
}: Props): React.ReactElement => (
  <svg width={size} height={size} viewBox='0 0 62.403 63.057' {...rest}>
    <defs>
      <marker id='Arrow1Mend' overflow='visible' orient='auto'>
        <path
          transform='matrix(-.4 0 0 -.4 -4 0)'
          d='m0 0 5-5-17.5 5 17.5 5z'
          style={{
            fill: axisColor,
            fillRule: 'evenodd',
            stroke: axisColor,
            strokeWidth: '1pt'
          }}
        />
      </marker>
      <marker id='Arrow1Send' overflow='visible' orient='auto'>
        <path
          transform='matrix(-.2 0 0 -.2 -1.2 0)'
          d='m0 0 5-5-17.5 5 17.5 5z'
          style={{
            fillRule: 'evenodd',
            stroke: curveColor,
            strokeWidth: '1pt'
          }}
        />
      </marker>
      <radialGradient
        id='radialGradient1370'
        cx='171.21'
        cy='196.85'
        r='200'
        gradientTransform='matrix(.14198 .078075 -.072771 .12591 64.268 100.86)'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={colorGradient} offset='0' />
        <stop stopColor={color} offset='1' />
      </radialGradient>
    </defs>
    <g transform='translate(-55.171 -111.49)'>
      <g transform='matrix(.32814 .32814 -.33149 .33149 93.815 70.626)' />
      <path
        d='m55.527 174.2 60.662-61.305'
        style={{
          fill: 'none',
          markerEnd: 'url(#Arrow1Mend)',
          stroke: axisColor
        }}
      />
    </g>
    <g transform='translate(-55.171 -111.49)'>
      <path
        d='m109.1 146.28a25 24.991 0 1 1-50 0 25 24.991 0 1 1 50 0z'
        style={{ fill: 'url(#radialGradient1370)', fillRule: 'evenodd' }}
      />
    </g>
    <g transform='translate(-55.171 -111.49)'>
      <path
        d='m69.907 119.83c-23.883-7.7073-8.868 14.247 9.0714 30.819 18.894 17.454 44.717 22.569 33.47 3.9455'
        style={{
          fill: 'none',
          markerEnd: 'url(#Arrow1Send)',
          stroke: curveColor,
          strokeWidth: 2
        }}
      />
      <rect
        transform='matrix(-.33149 .33149 -.32814 -.32814 93.815 70.626)'
        x='126.23'
        y='-112.91'
        width='3.5547'
        height='27.876'
        style={{ strokeOpacity: 0 }}
      />
      <rect
        transform='matrix(.32814 .32814 -.33149 .33149 93.815 70.626)'
        x='97.099'
        y='113.38'
        width='3.5547'
        height='27.876'
        style={{ strokeOpacity: 0 }}
      />
    </g>
  </svg>
);
