export const Unicode = {
  SPACE: '\u0020',
  NBSP: '\u00A0',
  APOSTROPHE: '\u0027',
  CIRCLE: '\u25CF',
  COPYRIGHT: '\u00A9',
  LAQUO: '\u00AB',
  MDASH: '\u2014',
  MIDDOT: '\u00B7',
  ONE_QUARTER: '\u00BC',
  ONE_HALF: '\u00BD',
  RAQUO: '\u00BB',
  SUPER_2: '\u00B2',
  THREE_QS: '\u00BE',
  HAND_WAVING: '\u{1F44B}'
};
