import type React from 'react';
import { createContext } from 'react';
import type { Spectrum1D } from '../../model';

export interface Store {
  datum?: Spectrum1D.Datum;
  setDatum?: React.Dispatch<React.SetStateAction<Spectrum1D.Datum | undefined>>;
}

export const Context = createContext<Store>({});
