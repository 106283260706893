import React from 'react';
import { Footer, InfoPageWrapper } from '../components';

const resources: { label: string; link: string; description: React.ReactElement | string }[] = [
  {
    label: 'NMR Solvents and Impurities: Organometallics 2010, 29, 9, 2176-2179',
    link: 'https://doi.org/10.1021/om100106e',
    description: (
      <>
        General reference for <sup>1</sup>H and <sup>13</sup>C chemical shifts of common solvents
        and impurities. Frequently found in labs around the world, these tables are worth keeping on
        hand!
      </>
    )
  },
  {
    label: 'Prof. Hans Reich Collection of NMR Topics',
    link: 'https://organicchemistrydata.org/hansreich/resources/nmr/?page=nmr-content',
    description:
      'A beautifully detailed guide to NMR spectroscopy, this collection covers many practical and theoretical aspects of interest to those pursuing mastery of this technique. Also contains problem sets to hone your skills!'
  },
  {
    label: 'The Basics of NMR (Created by Joseph P. Hornak, Ph.D.)',
    link: 'https://www.cis.rit.edu/htbooks/nmr/nmr-main.htm',
    description:
      'An excellent introduction to NMR for newcomers to the field. Stretches from theoretical considerations to practical aspects of NMR spectroscopy.'
  },
  {
    label:
      'WebSpectra (Problems in NMR and IR Spectroscopy, Directed by Prof. Craig A. Merlic with support from Dr. Jane Strouse)',
    link: 'https://webspectra.chem.ucla.edu/',
    description:
      'A useful selection of problems for training in structural assignment via NMR. Problems are organized according to difficulty, so you can progressively challenge yourself and improve!'
  },
  {
    label:
      'Organic Structure Elucidation (Online Workbook of Problems in NMR, by Dr. Bradley D. Smith, Dr. Bill Boggess, and Dr. Jaroslav Zajicek)',
    link: 'https://www3.nd.edu/~smithgrp/structure/workbook.html',
    description:
      'Another great workbook of structural assignment problems! Also organized by difficulty to facilitate continuous improvement.'
  }
];

export const ResourcesRoute = (): React.ReactElement => (
  <InfoPageWrapper>
    <div className='info-content flex-grow-1'>
      <h2 className='py-3 m-0'>Resources</h2>
      <ol>
        {resources.map((resource) => (
          <li className='mb-2' key={resource.link}>
            {resource.label}
            <div className='ml-3'>
              <p className='m-0 py-2'>{resource.description}</p>
              <a href={resource.link}>
                <i>View External</i>
              </a>
            </div>
          </li>
        ))}
      </ol>
    </div>
    <Footer />
  </InfoPageWrapper>
);
