import React from 'react';

const DEFAULT_SIZE = 16;
const DEFAULT_COLOR = 'rgb(202, 153, 18)';
const DEFAULT_SECONDARY_COLOR = 'rgb(0, 160, 0)';

type Props = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
  secondaryColor?: string;
};

export const PeakPickingIcon = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
  secondaryColor = DEFAULT_SECONDARY_COLOR
}: Props): React.ReactElement => (
  <svg width={size} height={size} viewBox='0 0 98.465 99.069'>
    <defs>
      <marker id='Arrow2Sstart' overflow='visible' orient='auto'>
        <path
          transform='matrix(.3 0 0 .3 -.69 0)'
          d='m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z'
          fill={secondaryColor}
          stroke={secondaryColor}
          style={{
            strokeWidth: '.625',
            fillRule: 'evenodd',
            strokeLinejoin: 'round'
          }}
        />
      </marker>
      <marker id='Arrow2Sstart-9' overflow='visible' orient='auto'>
        <path
          transform='matrix(.3 0 0 .3 -.69 0)'
          d='m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z'
          fill={secondaryColor}
          stroke={secondaryColor}
          style={{
            strokeWidth: '.625',
            fillRule: 'evenodd',
            strokeLinejoin: 'round'
          }}
        />
      </marker>
      <marker id='Arrow2Sstart-9-2' overflow='visible' orient='auto'>
        <path
          transform='matrix(.3 0 0 .3 -.69 0)'
          d='m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z'
          fill={secondaryColor}
          stroke={secondaryColor}
          style={{
            strokeWidth: '.625',
            fillRule: 'evenodd',
            strokeLinejoin: 'round'
          }}
        />
      </marker>
    </defs>
    <g transform='translate(-61.214 -72.747)' fill='none'>
      <path
        transform='translate(0 -10)'
        d='m139.58 120.65v-33.722'
        stroke={secondaryColor}
        style={{ strokeWidth: '10', markerStart: 'url(#Arrow2Sstart)' }}
      />
      <path
        d='m62.656 170.64s11.009-36.862 16.075-36.891c5.2219-0.03 5.2632 35.955 11.335 36.272 6.4962 0.33967 12.452-52.487 19.373-52.554 6.4132-0.0624 8.2278 52.059 15.869 52.142 6.1335 0.066 8.7601-36.069 14.22-35.448 5.0028 0.56893 18.136 36.272 18.136 36.272'
        stroke={color}
        style={{ strokeWidth: '7.5' }}
      />
      <path
        transform='translate(0 -10)'
        d='m109.22 109.28v-33.722'
        stroke={secondaryColor}
        style={{ strokeWidth: '10', markerStart: 'url(#Arrow2Sstart-9)' }}
      />
      <path
        transform='translate(0 -10)'
        d='m78.305 121.24v-33.722'
        stroke={secondaryColor}
        style={{ strokeWidth: '10', markerStart: 'url(#Arrow2Sstart-9-2)' }}
      />
    </g>
  </svg>
);
