import type { StandardLonghandProperties, StandardShorthandProperties } from 'csstype';
import _ from 'lodash';
import React from 'react';
import type { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';

export const Item = ({ children, style, ...props }: Item.Props): ReactElement => {
  const picked = _.pick(
    props,
    'alignSelf',
    'gridColumnEnd',
    'gridColumnStart',
    'gridRowEnd',
    'gridRowStart',
    'justifySelf',
    'gridArea',
    'gridColumn',
    'gridRow',
    'placeSelf'
  );

  const passthru = _.omit(props, Object.keys(picked));

  return (
    <div style={{ ...picked, ...style }} {...passthru}>
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Item {
  export type Props = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> &
      Pick<
        StandardLonghandProperties,
        | 'alignSelf'
        | 'gridColumnEnd'
        | 'gridColumnStart'
        | 'gridRowEnd'
        | 'gridRowStart'
        | 'justifySelf'
      > &
      Pick<StandardShorthandProperties, 'gridArea' | 'gridColumn' | 'gridRow' | 'placeSelf'>
  >;
}
