import type { ThemeProps } from 'styled-components';
import { Utils as _Utils } from './common';
import { Provider as _Provider } from './provider';
import type {
  Name as _Name,
  Swatch as _Swatch,
  Theme as _Theme,
  Variant as _Variant
} from './types';
import { useViewport as _useViewport } from './use-viewport';

export type Theme = _Theme;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Theme {
  export type Props = ThemeProps<_Theme>;
  export type Name<T extends string = never> = _Name<T>;
  export type Swatch<T extends string = never> = _Swatch<T>;
  export type Variant = _Variant;

  /* eslint-disable */
  export import Provider = _Provider;
  export import Utils = _Utils;

  export const useViewport = _useViewport;
}
