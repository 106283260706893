import type { Theme } from '@splotch/core-ui';
import styled from 'styled-components';

export const InfoPageWrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }: { theme: Theme }): string =>
    theme.colors.secondary.lighten(0.7).alpha(0.25).toString()};
  display: flex;
  flex-direction: column;

  .info-content {
    width: var(--max-page-width);
    margin: 0 auto;
  }
`;
