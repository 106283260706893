import { assert } from '@sindresorhus/is';
import type { Any } from '@splotch/core-utils';
import { freeze, produce } from 'immer';
import _ from 'lodash';
import create from 'zustand';
import type { Environment } from '../model';
import type { Draft, State, Store } from './types';

let __singleton: Store | undefined;

// Mimic the Zustand `useStore` interface as a lazy-loaded singleton
export const store = new Proxy<Store>(<Any>_.noop, {
  apply(_unused, thiz: unknown, args?: Any): Any {
    assert.object(__singleton);

    return __singleton.apply(thiz, args);
  },
  get(_unused, prop: keyof Store): Any {
    assert.object(__singleton);

    return __singleton[prop];
  }
});

// Create and initialize the singleton store
export const initializer = (environment: Environment): Store => {
  assert.undefined(__singleton);

  // Create the underlying Zustand store
  const zustand = create<State>(() =>
    freeze({
      environment
    })
  );

  // Immerize the zustand store
  Object.defineProperty(zustand, 'produce', {
    get() {
      return (fn: (draft: Draft) => void): void => {
        zustand.setState(produce(fn));
      };
    }
  });

  __singleton = <Store>zustand;

  return __singleton;
};
