
import { FileUtils } from '../file-utils';

// @fixme: Fix the typing on these props
interface Props {
  files: File[];
}

export const loadDX = async ({ files }: Props): Promise<FileUtils.LoadedFile[]> => {
  const loadedFiles = await FileUtils.loadFiles(files, {
    asBuffer: true
  });

  return loadedFiles;
};
