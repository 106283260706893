import { useCallback, useLayoutEffect, useState } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
}

export type UseDimensionsHook = [
  (node: HTMLDivElement) => void,
  DimensionObject,
  HTMLDivElement | undefined
];

const getDimensionObject = (node: HTMLDivElement): DimensionObject => {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height
  };
};

export const useDimensions = (): UseDimensionsHook => {
  const [dimensions, setDimensions] = useState<DimensionObject>({
    width: 0,
    height: 0
  });
  const [node, setNode] = useState<HTMLDivElement>();

  const ref = useCallback((_node: HTMLDivElement) => {
    setNode(_node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      window.requestAnimationFrame(() => {
        setDimensions(getDimensionObject(node));
      });
    }
  }, [node]);

  return [ref, dimensions, node];
};
