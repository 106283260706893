import { MagneticLogo as _MagneticLogo } from './magnetic-logo';
import { MethaneLogo as _MethaneLogo } from './methane-logo';
import { MultiplicityIcon as _MultiplicityIcon } from './multiplicity-icon';
import { PeakPickingIcon as _PeakPickingIcon } from './peak-picking-icon';
import { PhasingIcon as _PhasingIcon } from './phasing-icon';

export namespace Icons {
  export const MultiplicityIcon = _MultiplicityIcon;
  export const PeakPickingIcon = _PeakPickingIcon;
  export const PhasingIcon = _PhasingIcon;
  export const MagneticLogo = _MagneticLogo;
  export const MethaneLogo = _MethaneLogo;
}
