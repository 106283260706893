import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, Router, Switch } from 'react-router-dom';
import { Navbar } from './components';
import type { Environment } from './model';
import { Providers } from './providers';
import {
  BugReportRoute,
  ContactRoute,
  FaqRoute,
  FeatureRequestRoute,
  HomeRoute,
  LegalRoute,
  ResourcesRoute,
  SpectrumWrapper,
  TutorialRoute
} from './routes';
import { Store } from './store';

interface Props {
  environment: Environment;
}

const history = createBrowserHistory();

export const App = ({ environment }: Props): React.ReactElement | null => {
  const [isReady, setReady] = useState(false);

  useEffect(
    () => {
      // Initialize the global store
      Store.initialize(environment);

      // https://thisthat.dev/border-vs-outline
      document.addEventListener('mousedown', () => {
        document.body.classList.add('intent-mouse');
      });

      document.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
          document.body.classList.remove('intent-mouse');
        }
      });

      /*
       * Initialize Google Analytics and Sentry for Production Only
       */
      if (environment.production) {
        // Log the current page we're on since history.listen only listens for changes
        ReactGA.set({ page: history.location.pathname, anonymizeIp: true });
        ReactGA.pageview(history.location.pathname);

        ReactGA.initialize(environment.GOOGLE_ANALYTICS_TRACKING_CODE);

        // https://stackoverflow.com/a/34837445/11646872
        history.listen((location) => {
          ReactGA.set({ page: location.pathname });
          ReactGA.pageview(location.pathname);
        });

        // Initialize Sentry
        const browserTracing = new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        });

        Sentry.init({
          dsn: environment.SENTRY_DSN,
          autoSessionTracking: true,
          integrations: [browserTracing],
          tracesSampleRate: 1.0
        });
      }

      setReady(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return isReady ? (
    <Providers>
      <Router history={history}>
        <Navbar />
        <div className='scaffold'>
          <Switch>
            <Route exact path='/'>
              <HomeRoute />
            </Route>
            <Route path='/spectrum'>
              <SpectrumWrapper />
            </Route>
            <Route path='/feature-request'>
              <FeatureRequestRoute />
            </Route>
            <Route path='/tutorial'>
              <TutorialRoute />
            </Route>
            <Route path='/resources'>
              <ResourcesRoute />
            </Route>
            <Route path='/faq'>
              <FaqRoute />
            </Route>
            <Route path='/contact'>
              <ContactRoute />
            </Route>
            <Route path='/bug-report'>
              <BugReportRoute />
            </Route>
            <Route path='/legal/:document'>
              <LegalRoute />
            </Route>
          </Switch>
        </div>
      </Router>
    </Providers>
  ) : null;
};
