export const solventToHtml = (solvent: string): string => {
  let html = '';
  let prevChWasLetter = true;

  for (let i = 0; i < solvent.length; i++) {
    const ch = solvent.toLowerCase().charCodeAt(i);
    const curChar = solvent.charAt(i);

    if (ch >= 97 && ch <= 122) {
      // We're looking at a letter
      html += prevChWasLetter ? curChar : `</sub>${curChar}`;

      prevChWasLetter = true;
    } else if (ch >= 48 && ch <= 57) {
      // We're looking at a digit
      html += prevChWasLetter ? `<sub>${curChar}` : curChar;

      prevChWasLetter = false;
    } else {
      html += curChar;
      prevChWasLetter = true;
    }
  }

  if (!prevChWasLetter) {
    html += '</sub>';
  }

  return html;
};
