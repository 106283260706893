import type { Environment } from '../model';
import { initializer } from './factory';
import { SpectrumStore } from './spectrum-store';
import type * as Types from './types';

export type Store = Types.Store;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Store {
  export type State = Types.State;
  export type Draft = Types.Draft;

  // Initialize the store
  export const initialize = (environment: Environment): Store => initializer(environment);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export import Spectrum = SpectrumStore;
}
