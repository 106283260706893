import md5 from 'md5';

export type Hash = string;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Hash {
  export const DIGEST_LENGTH = 8;

  export const make = (value: Buffer | string): Hash => md5(value);
  export const digest = (value: Buffer | string): string => make(value).slice(0, DIGEST_LENGTH);
}
