import { Context as _Context } from './context';
import { Provider as _Provider } from './provider';
import { UseAddSpectra } from './use-add-spectra';

export namespace SpectrumStore {
  export const Context = _Context;
  export const Provider = _Provider;

  export const { useAddSpectra } = UseAddSpectra;
}
