import _ from 'lodash';
import { Nix } from './nix';

export type Scalar = boolean | number | string;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Scalar {
  export interface NumberOptions {
    positive?: boolean;
    nonZero?: boolean;
  }

  export interface StringOptions {
    trim?: boolean;
    separator?: string; // Used when converting an array to a string
  }

  export type Options = NumberOptions & StringOptions;

  /**
   * Returns the specified value as a scalar.
   *
   * - Zero length strings return `undefined` (eg. `normalize('') === undefined`).
   * - If an array is specified, it is first flattened and its zeroth element is converted to a scalar (eg. `normalize([1,2,3]) === 1`)
   * - Objects with an overridden `toString` method will be converted to a string via their `toString` function.
   *
   * @param value - The value to be converted to a `Scalar`.
   * @param options - Normalization options.
   * @returns The scalar value or `undefined`.
   */
  export const normalize = (
    value: unknown,
    options: Options = { trim: true }
  ): Scalar | undefined => {
    let result: Scalar | undefined;

    if (!Nix.isNil(value)) {
      if (typeof value === 'string') {
        // Value is a string
        const stringVal = options.trim ? value.trim() : value;

        result = stringVal.length > 0 ? stringVal : undefined;
      } else if (typeof value === 'number') {
        // Value is a number
        result =
          (options.positive && value <= 0) || (options.nonZero && value === 0) ? undefined : value;
      } else if (typeof value === 'boolean') {
        // Value is a boolean
        result = value;
      } else if (Array.isArray(value)) {
        // Value is an array
        const flattened = value.flat();

        result = flattened.length ? normalize(flattened[0], options) : undefined;
      } else if (
        !_.isEmpty(value) &&
        _.isObjectLike(value) &&
        typeof (<Record<string, unknown>>value).toString === 'function'
      ) {
        // Value is an object
        const objectVal = <Record<string, unknown>>value;

        result =
          objectVal.toString !== Object.prototype.toString
            ? normalize(objectVal.toString())
            : undefined;
      }
    }

    return result;
  };
}
