import React, { useCallback, useContext } from 'react';
import { withTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { PEAK_PICKING_STAGES } from '../../constants';
import { getFloategrals, rationalize, useDimensions } from '../../utils';
import { NmrGraph } from './nmr-graph';
import { Sidebar } from './sidebar';
import { SpectrumContext } from './spectrum-store';
import { Tooltips } from './tooltips';

interface Point {
  x: number;
  y: number;
}

export const SpectrumRoute = withTheme(({ theme }) => {
  const [containerRef, { width, height }] = useDimensions();

  const [
    { correctedData, peaks, peakPickingStatus },
    { updatePeakPickingStatus, setPeaks }
  ] = useContext(SpectrumContext);

  const handleClick = useCallback(
    (point: { x: number }) => {
      switch (peakPickingStatus.state) {
        case PEAK_PICKING_STAGES.INACTIVE:
          return;
        case PEAK_PICKING_STAGES.LEFT_LIMIT:
          updatePeakPickingStatus?.({
            ...peakPickingStatus,
            state: PEAK_PICKING_STAGES.RIGHT_LIMIT,
            leftLimit: point.x
          });

          return;
        case PEAK_PICKING_STAGES.RIGHT_LIMIT:
          // eslint-disable-next-line no-case-declarations
          const newPeaks = [
            ...peaks,
            {
              id: uuid(),
              center: Number.parseFloat(
                (point.x + (peakPickingStatus.leftLimit! - point.x) / 2).toFixed(4)
              ),
              range: {
                xMin: point.x,
                xMax: peakPickingStatus.leftLimit!
              }
            }
          ].sort((a, b) => a.center - b.center);
          // eslint-disable-next-line no-case-declarations
          const ranges = newPeaks.map((i) => i.range);
          // eslint-disable-next-line no-case-declarations
          const floategrals = getFloategrals(correctedData, ranges);
          // eslint-disable-next-line no-case-declarations
          const rationals = rationalize(floategrals);

          newPeaks.forEach((peak, index) => {
            peak.area = rationals[index];
          });
          setPeaks?.(newPeaks);
          updatePeakPickingStatus?.({
            state: PEAK_PICKING_STAGES.LEFT_LIMIT
          });

          return;
        case PEAK_PICKING_STAGES.FIRST_COUPLING_PEAK:
          updatePeakPickingStatus?.({
            ...peakPickingStatus,
            state: PEAK_PICKING_STAGES.SECOND_COUPLING_PEAK,
            firstCouplingPeak: point.x
          });

          return;
        case PEAK_PICKING_STAGES.SECOND_COUPLING_PEAK:
          // eslint-disable-next-line no-case-declarations
          const updatedPeaks = [...peaks];
          // eslint-disable-next-line no-case-declarations
          const updatedPeak = updatedPeaks.find((peak) => peak.center === peakPickingStatus.center);

          if (updatedPeak) {
            updatedPeak.couplingFactors =
              !updatedPeak.couplingFactors || !updatedPeak.couplingFactors.length
                ? Math.abs(peakPickingStatus.firstCouplingPeak! - point.x)
                    .toFixed(3)
                    .toString()
                : `${updatedPeak.couplingFactors},${Math.abs(
                    peakPickingStatus.firstCouplingPeak! - point.x
                  )
                    .toFixed(3)
                    .toString()}`;
            setPeaks?.(updatedPeaks);
          }
          updatePeakPickingStatus?.({
            state: PEAK_PICKING_STAGES.LEFT_LIMIT
          });
      }
    },
    [correctedData, peakPickingStatus, peaks, setPeaks, updatePeakPickingStatus]
  );

  const onClickDataPoint = useCallback(
    (event: {
      point: {
        x: number;
      };
    }) => {
      handleClick(event.point);
    },
    [handleClick]
  );

  const onClickChart = useCallback(
    (event: { xAxis: { value: number }[] }) => {
      const eventXValue = Number.parseFloat(event.xAxis[0].value.toFixed(4));

      // Search for the point in the series closest to the click event
      let [closestPoint] = correctedData;

      correctedData.forEach((point) => {
        if (Math.abs(point.x - eventXValue) < Math.abs(closestPoint.x - eventXValue)) {
          closestPoint = point;
        }
      });
      handleClick(closestPoint);
    },
    [correctedData, handleClick]
  );

  /**
   * Formats chart tooltip for series data
   *
   * @param {Point} [this] - An object of kind {x:number, y:number}.
   * @returns {string} A string formatted HTML element
   */
  // eslint-disable-next-line func-style
  function tooltipFormatter(this: Point): string {
    switch (peakPickingStatus.state) {
      case PEAK_PICKING_STAGES.LEFT_LIMIT:
        return Tooltips.LEFT_INTEGRATION_LIMIT(this, theme);
      case PEAK_PICKING_STAGES.RIGHT_LIMIT:
        return Tooltips.RIGHT_INTEGRATION_LIMIT(this, theme);
      case PEAK_PICKING_STAGES.FIRST_COUPLING_PEAK:
        return Tooltips.FIRST_COUPLING_PEAK(this, theme);
      case PEAK_PICKING_STAGES.SECOND_COUPLING_PEAK:
        return Tooltips.SECOND_COUPLING_PEAK(this, theme);
      default:
        return Tooltips.STANDARD_CURSOR(this, theme);
    }
  }

  return (
    <div className='d-flex w-100 h-100'>
      <Sidebar />
      <div className='flex-grow-1' ref={containerRef}>
        <NmrGraph
          height={height}
          width={width}
          peaks={peaks}
          onClickDataPoint={onClickDataPoint}
          onClickChart={onClickChart}
          tooltipFormatter={tooltipFormatter}
        />
      </div>
    </div>
  );
});
