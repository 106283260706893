import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';
import { Viewport } from '../viewport';
import type { Theme as _Theme } from './types';

export const useViewport = (): Viewport => {
  const { breakpoints } = React.useContext<_Theme>(ThemeContext);

  const sm = useMediaQuery({ minWidth: breakpoints[Viewport.SM] });
  const md = useMediaQuery({ minWidth: breakpoints[Viewport.MD] });
  const lg = useMediaQuery({ minWidth: breakpoints[Viewport.LG] });
  const xl = useMediaQuery({ minWidth: breakpoints[Viewport.XL] });

  return xl ? Viewport.XL : lg ? Viewport.LG : md ? Viewport.MD : sm ? Viewport.SM : Viewport.XS;
};
