import React from 'react';
import { Footer } from '../../components';
import { SectionDivider, StyledHero } from './__styled';
import { GetInvolved } from './get-involved';
import { LandingDropzone } from './landing-dropzone';

export const HomeRoute = (): React.ReactElement => (
  <>
    <StyledHero fluid className='d-flex flex-column align-items-center justify-content-center'>
      <div className='img-container'>
        <div className='hero-content'>
          <div>
            <h1 className='font-weight-bold'>
              Explore NMR spectra with <br />
              <i>Splotch Labs</i>
            </h1>
            <p className='mt-3'>
              We&apos;re on a mission to provide convenient access to tools that help streamline
              scientific research, starting with anaylitical techniques in chemistry.
            </p>
            <LandingDropzone />
          </div>
        </div>
      </div>
    </StyledHero>
    <SectionDivider />
    <GetInvolved />
    <Footer />
  </>
);
