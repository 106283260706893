import type { Obj } from '@splotch/core-utils';
import { Str } from '@splotch/core-utils';
import Color from 'color';
import { Viewport } from '../../viewport';
import type { Theme } from '../types';

const toString = (value: unknown): string => {
  const strValue = Str.normalize(value);

  return strValue ?? '#000000';
};

const toColor = (value: unknown): Color => Color(toString(value));

export namespace Utils {
  /**
   * Converts variables exported from a SASS file into a `Theme` object.
   *
   * https://github.com/css-modules/icss
   *
   * @param variables Exported SASS variables.
   * @returns Theme object.
   */
  export const toTheme = (variables: Obj.Rec): Theme => ({
    colors: {
      black: toColor(variables['colors-black']),
      dark: toColor(variables['colors-dark']),
      danger: toColor(variables['colors-danger']),
      light: toColor(variables['colors-light']),
      primary: toColor(variables['colors-primary']),
      secondary: toColor(variables['colors-secondary']),
      tertiary: toColor(variables['colors-tertiary']),
      quartenary: toColor(variables['colors-quartenary']),
      success: toColor(variables['colors-success']),
      info: toColor(variables['colors-info']),
      muted: toColor(variables['colors-muted']),
      warning: toColor(variables['colors-warning']),
      white: toColor(variables['colors-white'])
    },

    breakpoints: {
      [Viewport.XS]: toString(variables['breakpoints-xs']),
      [Viewport.SM]: toString(variables['breakpoints-sm']),
      [Viewport.MD]: toString(variables['breakpoints-md']),
      [Viewport.LG]: toString(variables['breakpoints-lg']),
      [Viewport.XL]: toString(variables['breakpoints-xl'])
    }
  });
}
