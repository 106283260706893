import React from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Grid } from './grid';

const Wrapper = styled(Grid)`
  column-gap: var(--spacer-16px);
  row-gap: var(--spacer-12px);
  line-height: var(--line-height-sm);
`;

export const KVPairs = ({ children, ...props }: KVPairs.Props): ReactElement => (
  <Wrapper gridTemplateColumns='min-content 1fr' {...props}>
    {children}
  </Wrapper>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace KVPairs {
  export type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;
}
