import type { Any } from '@splotch/core-utils';
import type { Spectrum1D } from '../spectrum-1d';
import {
  Absolute as _Absolute,
  AutoPhaseCorrection as _AutoPhaseCorrection,
  BaselineCorrection as _BaselineCorrection,
  CenterMean as _CenterMean,
  DigitalFilter as _DigitalFilter,
  EquallySpaced as _EquallySpaced,
  FFT as _FFT,
  FromTo as _FromTo,
  LineBroadening as _LineBroadening,
  Pareto as _Pareto,
  PhaseCorrection as _PhaseCorrection,
  ShiftX as _ShiftX,
  StandardDeviation as _StandardDeviation,
  ZeroFilling as _ZeroFilling
} from './1d';

export interface Filter {
  id: string;
  name: string;
  label?: string;
  isDeleteAllow: boolean;
  flag?: boolean;
  value?: Any;
  options?: Any;
  error?: string;
}

export namespace Filter {
  export interface ReduceResult {
    once: boolean;
    reduce?: Any;
  }

  export interface FilterFunction {
    id: string;
    name: string;
    isApplicable: (datum: Spectrum1D.Datum) => boolean;
    reduce: (...args: Any) => ReduceResult;
    apply: (...args: Any) => void;
  }

  export const Absolute = _Absolute;
  export const AutoPhaseCorrection = _AutoPhaseCorrection;
  export const BaselineCorrection = _BaselineCorrection;
  export const CenterMean = _CenterMean;
  export const DigitalFilter = _DigitalFilter;
  export const EquallySpaced = _EquallySpaced;
  export const FFT = _FFT;
  export const FromTo = _FromTo;
  export const LineBroadening = _LineBroadening;
  export const Pareto = _Pareto;
  export const PhaseCorrection = _PhaseCorrection;
  export const ShiftX = _ShiftX;
  export const StandardDeviation = _StandardDeviation;
  export const ZeroFilling = _ZeroFilling;

  // @fixme: All uses of this should reference filter `id`, not `name`
  export const getFilterFunction = (name: string): FilterFunction | undefined => {
    const map: Record<string, FilterFunction> = {
      Absolute,
      AutoPhaseCorrection,
      BaselineCorrection,
      CenterMean,
      DigitalFilter,
      EquallySpaced,
      FFT,
      FromTo,
      LineBroadening,
      Pareto,
      PhaseCorrection,
      ShiftX,
      StandardDeviation,
      ZeroFilling
    };

    return map[name];
  };
}
