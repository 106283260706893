import { assert } from '@sindresorhus/is';
import type { Any } from '@splotch/core-utils';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace ZeroFilling {
  export const id = 'zeroFilling';
  export const name = 'Zero Filling';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !!datum.info.isFid;

  export const reduce = (_previousValue: Any, newValue: number): Filter.ReduceResult => ({
    once: true,
    reduce: newValue
  });

  export const apply = (datum: Spectrum1D.Datum, size: number): void => {
    if (!isApplicable(datum)) {
      throw new Error('zeroFilling not applicable on this data');
    }

    const digitalFilterApplied = datum.filters.some((e) => e.name === 'digitalFilter' && e.flag);

    const grpdly = datum.info.digitalFilter;

    assert.number(grpdly);
    const pointsToShift = grpdly > 0 && digitalFilterApplied ? Math.floor(grpdly) : 0;

    const { re, im, x } = datum.data;

    const newRE = new Float64Array(size);
    const newIM = new Float64Array(size);
    const newX = new Float64Array(size);

    const length = Math.min(size, re.length);

    newRE.set(re.slice(0, length - pointsToShift));

    if (im) {
      newIM.set(im.slice(0, length - pointsToShift));
    }

    newX.set(x.slice(0, length - pointsToShift));

    const diff = x[1] - x[0];
    let currentX = x[length - pointsToShift - 1];

    for (let i = length - pointsToShift; i < size; i++) {
      currentX += diff;
      newX[i] = currentX;
    }

    if (pointsToShift > 0 && pointsToShift < size) {
      newRE.set(re.slice(re.length - pointsToShift), size - pointsToShift);

      if (im) {
        newIM.set(im.slice(re.length - pointsToShift), size - pointsToShift);
      }
    }

    datum.data = { ...datum.data, ...{ re: newRE, ...(im ? { im: newIM } : {}), x: newX } };
  };
}
