import { faQuestion, faShareSquare, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useCallback, useContext, useState } from 'react';
import { Button, Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Icons } from '../../components';
import { KEY_MAP, PEAK_PICKING_STAGES } from '../../constants';
import { useKeyboardShortcut } from '../../utils';
import { StyledSidebar } from './_.styled';
import { HelpModal } from './help-modal';
import { Menus } from './menus';
import { SpectrumContext } from './spectrum-store';
import { SupportingInfoModal } from './supporting-info-modal';

export const Sidebar = (): React.ReactElement => {
  const [{ peakPickingStatus, chartRef }, { updatePeakPickingStatus }] = useContext(
    SpectrumContext
  );

  const peakPickingMenuRef = createRef<HTMLDivElement>();

  const [showPhaseCorrectionOverlay, setShowPhaseCorrectionOverlay] = useState(false);
  const [showExportOverlay, setShowExportOverlay] = useState(false);
  const [supportingInfoModalShow, setSupportingInfoModalShow] = useState(false);
  const [helpModalShow, setHelpModalShow] = useState(false);

  const inactivatePeakPickingStatus = useCallback(() => {
    updatePeakPickingStatus!((status) => {
      if (status.state !== PEAK_PICKING_STAGES.INACTIVE) {
        return { state: PEAK_PICKING_STAGES.INACTIVE };
      }

      return { ...status };
    });
  }, [updatePeakPickingStatus]);

  const togglePhaseCorrectionOverlay = useCallback(() => {
    setShowPhaseCorrectionOverlay(!showPhaseCorrectionOverlay);
    inactivatePeakPickingStatus();
    setShowExportOverlay(false);
  }, [showPhaseCorrectionOverlay, inactivatePeakPickingStatus]);

  const togglePeakPickingStatus = useCallback(() => {
    if (peakPickingStatus.state === PEAK_PICKING_STAGES.INACTIVE) {
      setShowPhaseCorrectionOverlay(false);
      setShowExportOverlay(false);

      updatePeakPickingStatus!({ state: PEAK_PICKING_STAGES.LEFT_LIMIT });
    } else {
      updatePeakPickingStatus!({ state: PEAK_PICKING_STAGES.INACTIVE });
    }
  }, [peakPickingStatus.state, updatePeakPickingStatus]);

  const showSupportingInfoModal = useCallback(() => {
    inactivatePeakPickingStatus();
    setSupportingInfoModalShow(true);
  }, [inactivatePeakPickingStatus, setSupportingInfoModalShow]);

  const toggleExportOverlay = useCallback(() => {
    setShowPhaseCorrectionOverlay(false);
    inactivatePeakPickingStatus();
    setShowExportOverlay(!showExportOverlay);
  }, [inactivatePeakPickingStatus, showExportOverlay]);

  const showHelpModal = useCallback(() => {
    inactivatePeakPickingStatus();
    setHelpModalShow(true);
  }, [inactivatePeakPickingStatus, setHelpModalShow]);

  useKeyboardShortcut([KEY_MAP.RESET_ZOOM.key], () => chartRef?.current?.chart.zoomOut());
  useKeyboardShortcut([KEY_MAP.PHASE_CORRECTION.key], togglePhaseCorrectionOverlay);
  useKeyboardShortcut([KEY_MAP.PICK_PEAKS.key], togglePeakPickingStatus);
  useKeyboardShortcut([KEY_MAP.VIEW_SUPPORTING_INFO.key], showSupportingInfoModal);
  useKeyboardShortcut([KEY_MAP.EXPORT_CHART.key], toggleExportOverlay);
  useKeyboardShortcut([KEY_MAP.HELP_MODAL.key], showHelpModal);

  return (
    <>
      <StyledSidebar className='d-flex flex-column justify-content-between'>
        <div>
          <OverlayTrigger
            trigger='click'
            rootClose
            placement='right'
            overlay={
              <Popover id='phasing-popover'>
                <Menus.Phasing />
              </Popover>
            }
            show={showPhaseCorrectionOverlay}
            onToggle={(show): void => {
              setShowPhaseCorrectionOverlay(show);
            }}
          >
            <div>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='Phasing-Tooltip'>
                    Phase Correction ({KEY_MAP.PHASE_CORRECTION.key.toUpperCase()})
                  </Tooltip>
                }
              >
                <Button
                  className='sidebar-button'
                  variant='outline-secondary'
                  onClick={inactivatePeakPickingStatus}
                >
                  <Icons.PhasingIcon size={28} />
                </Button>
              </OverlayTrigger>
            </div>
          </OverlayTrigger>

          <div ref={peakPickingMenuRef}>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='Peak-Analysis-Tooltip'>
                  Peak Analysis ({KEY_MAP.PICK_PEAKS.key.toUpperCase()})
                </Tooltip>
              }
            >
              <Button
                className={`sidebar-button ${
                  peakPickingStatus.state !== PEAK_PICKING_STAGES.INACTIVE ? 'open' : ''
                }`}
                variant='outline-secondary'
                onClick={togglePeakPickingStatus}
              >
                <Icons.PeakPickingIcon size={28} />
              </Button>
            </OverlayTrigger>
            <Overlay
              show={peakPickingStatus.state !== PEAK_PICKING_STAGES.INACTIVE}
              placement='right'
              target={peakPickingMenuRef.current}
              container={peakPickingMenuRef.current}
            >
              <Popover id='peak-picking-popover'>
                <Menus.PeakPicking />
              </Popover>
            </Overlay>
          </div>

          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip id='Supporting-Info-Tooltip'>
                View Supporting Info ({KEY_MAP.VIEW_SUPPORTING_INFO.key.toUpperCase()})
              </Tooltip>
            }
          >
            <Button
              className='sidebar-button'
              variant='outline-secondary'
              onClick={showSupportingInfoModal}
            >
              <FontAwesomeIcon icon={faSitemap} />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            trigger='click'
            rootClose
            placement='right'
            overlay={
              <Popover id='export-popover'>
                <Menus.Exporting />
              </Popover>
            }
            show={showExportOverlay}
            onToggle={(show): void => {
              setShowExportOverlay(show);
            }}
          >
            <div>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='Export-Tooltip'>
                    Export as Image ({KEY_MAP.EXPORT_CHART.key.toUpperCase()})
                  </Tooltip>
                }
              >
                <Button
                  className='sidebar-button'
                  variant='outline-secondary'
                  onClick={inactivatePeakPickingStatus}
                >
                  <FontAwesomeIcon icon={faShareSquare} />
                </Button>
              </OverlayTrigger>
            </div>
          </OverlayTrigger>
        </div>
        <div>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id='Help-Tooltip'>
                Need Help? ({KEY_MAP.HELP_MODAL.key.toUpperCase()})
              </Tooltip>
            }
          >
            <Button className='sidebar-button' variant='outline-secondary' onClick={showHelpModal}>
              <FontAwesomeIcon icon={faQuestion} />
            </Button>
          </OverlayTrigger>
        </div>
      </StyledSidebar>
      <SupportingInfoModal
        show={supportingInfoModalShow}
        onHide={(): void => {
          setSupportingInfoModalShow(false);
        }}
      />
      <HelpModal
        show={helpModalShow}
        onHide={(): void => {
          setHelpModalShow(false);
        }}
      />
    </>
  );
};
