import type { Any } from '@splotch/core-utils';
import { reimAutoPhaseCorrection } from 'ml-spectra-processing';
import type { Spectrum1D } from '../../spectrum-1d';

export namespace AutoPhaseCorrection {
  export const id = 'autoPhaseCorrection';
  export const name = 'Automatic phase correction';

  const defaultOptions = {
    minRegSize: 5,
    maxDistanceToJoin: 128,
    magnitudeMode: true,
    factorNoise: 5
  };

  export const isApplicable = (datum: Spectrum1D.Datum): boolean => {
    if (datum.info.isComplex && !datum.info.isFid) {
      return true;
    }

    return false;
  };

  export const reduce = (
    previousValue: Record<string, number>,
    newValue: Record<string, number>
  ): { once: boolean; reduce: Record<string, number> } => {
    const reduced = { ...previousValue };

    for (const k in reduced) {
      if (reduced[k]) {
        reduced[k] += newValue[k];
      }
    }

    return {
      once: true,
      reduce: reduced
    };
  };

  export const apply = (
    datum: Spectrum1D.Datum,
    options = {}
  ): {
    data: Any;
    ph0: number;
    ph1: number;
  } => {
    if (!isApplicable(datum)) {
      throw new Error('phaseCorrection not applicable on this data');
    }

    return reimAutoPhaseCorrection(datum.data, {
      ...defaultOptions,
      ...options
    });
  };
}
