import { Navbar } from 'react-bootstrap';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

interface Props {
  useSpectrumStyles?: boolean;
}

export const StyledNavbar = styled(Navbar)<Props>`
  box-shadow: 0 2px 4px rgb(45 62 80 / 12%);
  z-index: 2;

  .nav-fixed-width {
    width: var(--max-page-width);
    font-size: 18px;
    ${({ useSpectrumStyles }): SimpleInterpolation | null =>
      !useSpectrumStyles
        ? css`
            margin: 0 auto;
          `
        : null}
  }

  #basic-navbar-nav a {
    color: var(--dark);
    font-weight: bold;
  }
`;
