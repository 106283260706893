import React from 'react';

export const BASE_SAMPLE_DATA_S3_URL = 'https://splotch-labs-sample-data.s3.amazonaws.com';

export const CUSTOM_MULTIPLICITY = 'CUSTOM_MULTIPLICITY';

export const MULTIPLICITIES: { code: string; label: string }[] = [
  {
    code: 's',
    label: 'singlet'
  },
  {
    code: 'd',
    label: 'doublet'
  },
  {
    code: 't',
    label: 'triplet'
  },
  {
    code: 'q',
    label: 'quartet'
  },
  {
    code: 'quint',
    label: 'quintet'
  },
  {
    code: 'sextet',
    label: 'sextet'
  },
  {
    code: 'm',
    label: 'multiplet'
  },
  {
    code: 'dd',
    label: 'doublet of doublets'
  },
  {
    code: 'dt',
    label: 'doublet of triplets'
  },
  {
    code: 'dq',
    label: 'doublet of quartets'
  },
  {
    code: 'td',
    label: 'triplet of doublets'
  },
  {
    code: 'tt',
    label: 'triplet of triplets'
  },
  {
    code: 'ddd',
    label: 'doublet of doublet of doublets'
  },
  {
    code: 'ddt',
    label: 'doublet of doublet of triplets'
  },
  {
    code: 'dtd',
    label: 'doublet of triplet of doublets'
  },
  {
    code: 'tdd',
    label: 'triplet of doublet of doublets'
  },
  {
    code: 'dddd',
    label: 'doublet of doublet of doublet of doublets'
  },
  {
    code: 'br',
    label: 'broad signal'
  }
];

export enum PEAK_PICKING_STAGES {
  INACTIVE = 'PEAK_PICKING_STAGE_INACTIVE',
  LEFT_LIMIT = 'PEAK_PICKING_STAGE_LEFT_LIMIT',
  RIGHT_LIMIT = 'PEAK_PICKING_STAGE_RIGHT_LIMIT',
  FIRST_COUPLING_PEAK = 'PEAK_PICKING_STAGE_FIRST_COUPLING_PEAK',
  SECOND_COUPLING_PEAK = 'PEAK_PICKING_STAGE_SECOND_COUPLING_PEAK'
}

export const CHART_EXPORT_TYPES: Record<
  'JPEG' | 'PDF' | 'PNG' | 'SVG',
  {
    mimeType: 'application/pdf' | 'image/jpeg' | 'image/png' | 'image/svg+xml';
    description: string;
  }
> = {
  JPEG: {
    mimeType: 'image/jpeg',
    description: 'JPEG format, browser window resolution'
  },
  PNG: {
    mimeType: 'image/png',
    description: 'PNG format, transparency supported, browser window resolution'
  },
  PDF: {
    mimeType: 'application/pdf',
    description: 'PDF format'
  },
  SVG: {
    mimeType: 'image/svg+xml',
    description: 'SVG format, infinitely scalable'
  }
};

export const KEY_MAP: Record<
  | 'EXPORT_CHART'
  | 'HELP_MODAL'
  | 'PHASE_CORRECTION'
  | 'PICK_PEAKS'
  | 'RESET_ZOOM'
  | 'VIEW_SUPPORTING_INFO',
  { key: string; description: string }
> = {
  RESET_ZOOM: {
    key: 'z',
    description: 'Reset Zoom'
  },
  PHASE_CORRECTION: {
    key: 'c',
    description: 'Phase Correction'
  },
  PICK_PEAKS: {
    key: 'q',
    description: 'Pick Peaks'
  },
  VIEW_SUPPORTING_INFO: {
    key: 'w',
    description: 'View Supporting Info'
  },
  EXPORT_CHART: {
    key: 'e',
    description: 'Export Chart'
  },
  HELP_MODAL: {
    key: '/',
    description: 'Show this Info Window'
  }
};

export const SAMPLE_SPECTRA: { uuid: string; name: string }[] = [
  {
    uuid: '30dbabd0-6af7-4dea-9e1e-e38a1b5a5936',
    name: '1,2-Propanediol'
  },
  {
    uuid: '9bf566f7-720c-4f7c-904a-99f42205eae1',
    name: '1,3-Propanediol'
  },
  {
    uuid: '5b89939c-5596-497d-a2a9-9def7966f506',
    name: '<em>p</em>-cymene'
  },
  {
    uuid: '338b2341-eab5-4af4-bb4b-b57c9b15231a',
    name: 'Indole'
  },
  {
    uuid: '326c68e8-e192-473c-b074-89f12f44e8b7',
    name: 'Coumarin'
  },
  {
    uuid: 'b809d31c-63ff-4c11-bf01-a73dcee112d2',
    name: 'Elaidic Acid'
  },
  {
    uuid: '151ff848-d29b-4db6-97d2-8e4c53152647',
    name: 'Estrone'
  },
  {
    uuid: '778e5e08-5adf-45c1-a17f-d1efe3409108',
    name: 'Ergocalciferol'
  }
];

export const SOCIAL_MEDIA_LINKS: { to: string; icon: React.ReactElement }[] = [
  {
    to: 'https://www.facebook.com/splotch_io-101922438434314',
    icon: <i className='fab fa-facebook' />
  },
  {
    to: 'https://www.instagram.com/splotch_io/',
    icon: <i className='fab fa-instagram' />
  },
  {
    to: 'https://twitter.com/splotch_io',
    icon: <i className='fab fa-twitter' />
  },
  {
    to: 'https://www.linkedin.com/company/splotch',
    icon: <i className='fab fa-linkedin' />
  },
  {
    to: 'mailto:support@splotch.io',
    icon: <i className='fas fa-envelope' />
  }
];

export enum SENTRY_CATEGORIES {
  UPLOAD = 'uploads',
  SAMPLE_DATA = 'sample-data'
}

export const GOOGLE_FORM_URL =
  'https://docs.google.com/forms/u/4/d/e/1FAIpQLScYxpRF5Bfmf7dibmk7LKgadQ5ERiG5R6cp_PfNNok6VhiAvA/formResponse';
