import React from 'react';

export const Terms = (): React.ReactElement => (
  <div>
    <h2>Terms of Service</h2>
    <ol>
      <li>Terms</li>
      <p>
        By accessing our app, Splotch Labs, you are agreeing to be bound by these terms of service,
        all applicable laws and regulations, and agree that you are responsible for compliance with
        any applicable local laws. If you do not agree with any of these terms, you are prohibited
        from using or accessing Splotch. The materials contained in Splotch are protected by
        applicable copyright and trademark law.
      </p>
      <li>Use License</li>
      <p>
        Permission is granted to temporarily access Splotch Labs for personal, non-commercial
        transitory viewing only. This is the grant of a license, not a transfer of title, and under
        this license you may not:
      </p>
      <ul>
        <li>modify or copy the materials;</li>
        <li>
          use the materials for any commercial purpose, or for any public display (commercial or
          non-commercial);
        </li>
        <li>attempt to decompile or reverse engineer any software contained in Splotch Labs;</li>
        <li>remove any copyright or other proprietary notations from the materials; or</li>
        <li>
          transfer the materials to another person or &quot;mirror&quot; the materials on any other
          server.
        </li>
      </ul>
      <p>
        This license shall automatically terminate if you violate any of these restrictions and may
        be terminated by Splotch LLC at any time. Upon terminating your viewing of these materials
        or upon the termination of this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </p>
      <li>Disclaimer</li>
      <p>
        The materials within Splotch Labs are provided on an &apos;as is&apos; basis. Splotch LLC
        makes no warranties, expressed or implied, and hereby disclaims and negates all other
        warranties including, without limitation, implied warranties or conditions of
        merchantability, fitness for a particular purpose, or non-infringement of intellectual
        property or other violation of rights. Further, Splotch LLC does not warrant or make any
        representations concerning the accuracy, likely results, or reliability of the use of the
        materials on its website or otherwise relating to such materials or on any sites linked to
        Splotch.
      </p>
      <li>Limitations</li>
      <p>
        In no event shall Splotch LLC or its suppliers be liable for any damages (including, without
        limitation, damages for loss of data or profit, or due to business interruption) arising out
        of the use or inability to use Splotch, even if Splotch LLC or a Splotch LLC authorized
        representative has been notified orally or in writing of the possibility of such damage.
        Because some jurisdictions do not allow limitations on implied warranties, or limitations of
        liability for consequential or incidental damages, these limitations may not apply to you.
      </p>
      <li>Accuracy of materials</li>
      <p>
        The materials appearing in Splotch could include technical, typographical, or photographic
        errors. Splotch LLC does not warrant that any of the materials on Splotch are accurate,
        complete or current. Splotch LLC may make changes to the materials contained in Splotch at
        any time without notice. However Splotch LLC does not make any commitment to update the
        materials.
      </p>
      <li>Links</li>
      <p>
        Splotch LLC has not reviewed all of the sites linked to its app and is not responsible for
        the contents of any such linked site. The inclusion of any link does not imply endorsement
        by Splotch LLC of the site. Use of any such linked website is at the user&apos;s own risk.
      </p>
      <li>Modifications</li>
      <p>
        Splotch LLC may revise these terms of service for its app at any time without notice. By
        using Splotch you are agreeing to be bound by the then current version of these terms of
        service.
      </p>
      <li>Governing Law</li>
      <p>
        These terms and conditions are governed by and construed in accordance with the laws of
        North Carolina and you irrevocably submit to the exclusive jurisdiction of the courts in
        that State or location.
      </p>
    </ol>
  </div>
);
