/* eslint-disable import/no-internal-modules */
import type { Theme } from '@splotch/core-ui';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import AnnotationsModule from 'highcharts/modules/annotations';
import BoostModule from 'highcharts/modules/boost';
import ExportingModule from 'highcharts/modules/exporting';
import OfflineExportingModule from 'highcharts/modules/offline-exporting';
import React, { useContext, useEffect, useMemo } from 'react';
import { withTheme } from 'styled-components';
import { SpectrumContext } from './spectrum-store';

AnnotationsModule(Highcharts);
ExportingModule(Highcharts);
OfflineExportingModule(Highcharts);
BoostModule(Highcharts);

interface Props {
  theme: Theme;
  height: number;
  width: number;
  peaks: {
    id: string;
    center: number;
    range: {
      xMin: number;
      xMax: number;
    };
    area?: number;
    couplingFactors?: string;
  }[];
  onClickDataPoint: (event: {
    point: {
      x: number;
    };
  }) => void;
  onClickChart: (event: {
    xAxis: {
      value: number;
    }[];
  }) => void;
  tooltipFormatter: () => string;
}

export const NmrGraph = withTheme(
  ({ theme, height, width, peaks, onClickDataPoint, onClickChart, tooltipFormatter }: Props) => {
    const [{ chartRef, correctedData, peakPickingStatus }, { updateDataWindow }] = useContext(
      SpectrumContext
    );

    const formattedData = useMemo(() => correctedData.map((item) => [item.x, item.y]), [
      correctedData
    ]);

    useEffect(() => {
      if (chartRef?.current) {
        chartRef.current.chart.removeAnnotation('peak-labels');
        chartRef.current.chart.addAnnotation({
          id: 'peak-labels',
          draggable: '',
          labelOptions: {
            className: 'peak-label',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 0,
            style: {
              color: theme.colors.black.hex()
            }
          },
          labels: peaks.map((peak) => ({
            point: { x: peak.center, y: 35, xAxis: 0 },
            useHTML: true,
            // Inline styles below due to Exporting HTML issue, https://github.com/highcharts/highcharts/issues/14875
            format: `<span style="display:inline-block; -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -ms-transform: rotate(-90deg); -o-transform: rotate(-90deg);">--- ${peak.center}</span>`
          }))
        });
      }
    }, [peaks, chartRef, theme]);

    useEffect(() => {
      if (chartRef?.current) {
        Highcharts.removeEvent(chartRef.current.chart.xAxis[0], 'afterSetExtremes');
        Highcharts.addEvent(
          chartRef.current.chart.xAxis[0],
          'afterSetExtremes',
          (event: {
            trigger:
              | 'navigator'
              | 'pan'
              | 'rangeSelectorButton'
              | 'rangeSelectorInput'
              | 'scrollbar'
              | 'traverseUpButton'
              | 'zoom';
            max: number;
            min: number;
          }) => {
            if (event.trigger === 'zoom') {
              updateDataWindow!({
                min: event.min,
                max: event.max
              });
            }
          }
        );
      }
    }, [chartRef, updateDataWindow]);

    /*
     *using ref due to this existing bug in highcharts code:
     *https://github.com/highcharts/highcharts-react/issues/112
     */
    useEffect(() => {
      if (chartRef?.current) {
        Highcharts.removeEvent(chartRef.current.chart, 'click');
        Highcharts.removeEvent(chartRef.current.chart.series[0], 'click');
        Highcharts.addEvent(chartRef.current.chart.series[0], 'click', onClickDataPoint);
        Highcharts.addEvent(chartRef.current.chart, 'click', onClickChart);
      }
    }, [chartRef, onClickDataPoint, onClickChart]);

    const options = {
      chart: {
        zoomType: 'x',
        width,
        height,
        panKey: 'meta',
        panning: true,
        resetZoomButton: {
          position: {
            align: 'right',
            y: -31 // Above the graph so we don't conflict with user interaction
          }
        }
      },

      title: {
        text: 'Splotch Labs'
      },

      tooltip: {
        shared: true,
        useHTML: true,
        valueDecimals: 3,
        formatter: tooltipFormatter
      },

      xAxis: {
        reversed: true,
        crosshair: {
          color: theme.colors.danger.hex()
        },
        plotBands: peaks.map((peak) => ({
          color: theme.colors.quartenary.hex(),
          from: peak.range.xMin,
          to: peak.range.xMax
        })),
        plotLines:
          peakPickingStatus.leftLimit || peakPickingStatus.firstCouplingPeak
            ? [
                {
                  color: theme.colors.secondary.hex(),
                  value: peakPickingStatus.leftLimit ?? peakPickingStatus.firstCouplingPeak,
                  dashStyle: 'ShortDash',
                  width: 2,
                  zIndex: 3
                }
              ]
            : []
      },

      yAxis: {
        tickWidth: 2,
        tickPosition: 'inside',
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        minorTicks: true,
        opposite: true,
        min: -1,
        crosshair: null,
        lineWidth: 1,
        title: {
          enabled: false
        }
      },

      plotOptions: {
        line: {
          animation: {
            duration: 200
          },
          marker: {
            enabled: false
          }
        }
      },

      series: [
        {
          data: formattedData,
          type: 'line',
          color: theme.colors.primary.hex(),
          lineWidth: 1,
          name: 'Uploaded Spectrum'
        }
      ],

      credits: {
        enabled: false
      },

      // Disable accessibility so we can capture keystrokes elsewhere
      accessibility: {
        enabled: false
      },

      // Disable the default menu so that we can leverage the API calls through our own UI
      exporting: {
        enabled: false,
        allowHTML: true
      }
    };

    return <HighchartsReact highcharts={Highcharts} ref={chartRef} options={options} />;
  }
);
