import { assert } from '@sindresorhus/is';
import { Arr } from './arr';
import { Obj } from './obj';
import { Str } from './str';

export class Keyed {
  public readonly key: Keyed.Key;

  public constructor(key: Keyed.Key) {
    const k = Str.normalize(key);

    assert.string(k);

    this.key = k;
  }

  public toString(): string {
    return this.key;
  }
}

// istanbul ignore next
export namespace Keyed {
  export type Key = string;
  export type Like = Key | Keyed;

  /**
   * Determines if the specified value is a keyed object.
   *
   * @param value The value to be queried.
   * @returns Type assertion.
   */
  export const isKeyed = (value: unknown): value is Keyed =>
    value instanceof Keyed ||
    (Obj.isObject(value) &&
      value.hasOwnProperty('key') &&
      // eslint-disable-next-line
      typeof (<any>value).key === 'string');

  export function toKey(value: Like): Key;
  export function toKey(value: readonly Like[]): Key[];
  export function toKey(value: Like | readonly Like[]): Key | Key[];

  /**
   * Converts the specified keyed or key-like value(s) to key(s).
   *
   * @param input Value(s) to be normalized.
   * @returns Normalized value(s).
   */
  // eslint-disable-next-line func-style
  export function toKey(input: Like | readonly Like[]): Key | Key[] {
    const keys = Arr.normalize(input).map((item: Like): Key => (isKeyed(item) ? item.key : item));

    return Array.isArray(input) ? keys : keys[0];
  }
}
