import type { Any } from '@splotch/core-utils';
import { fromBruker } from 'nmr-parser';
import type { Spectrum1D } from '../../../model';
import { fromBruker as getBrukerData } from '../../converters';
import type { UsedColors } from '../../get-color';

interface Options {
  xy?: boolean;
  noContours?: boolean;
  keepOriginal?: boolean;
  display?: {
    name?: string;
  };
}

export const bruker = async (
  options: Options,
  data: Any,
  usedColors: UsedColors
): Promise<Spectrum1D.Datum[]> => {
  const spectra = [];

  const result = await fromBruker(data, {
    xy: true,
    noContours: true,
    keepOriginal: true
  });
  const entries = result;

  for (const entry of entries) {
    const { info, dependentVariables } = entry;

    if (info.dimension === 1) {
      if (dependentVariables[0].components) {
        spectra.push(
          getBrukerData(
            entry,
            {
              ...options,
              display: { ...options.display }
            },
            usedColors
          )
        );
      }
    } else if (info.dimension === 2) {
      // 2D case
    }
  }

  return spectra;
};
