export const COLORS = [
  '#C10020',
  '#007D34',
  '#803E75',
  '#FF6800',
  '#B32851',
  '#7F180D',
  '#232C16',
  '#A6BDD7',
  '#CEA262',
  '#817066',
  '#FF8E00',
  '#F6768E',
  '#00538A',
  '#FF7A5C',
  '#53377A',
  '#FFB300',
  '#F4C800',
  '#93AA00',
  '#593315',
  '#F13A13'
];

export interface UsedColors {
  '1d'?: string[];
  '2d'?: string[];
}

const color2D: Record<string, { positiveColor: string; negativeColor: string }> = {
  cosy: { positiveColor: 'darkblue', negativeColor: 'blue' },
  roesy: { positiveColor: '#e75480', negativeColor: 'yellow' },
  noesy: { positiveColor: '#e75480', negativeColor: 'yellow' },
  tocsy: { positiveColor: 'green', negativeColor: 'yellow' },
  hsqc: { positiveColor: 'black', negativeColor: 'yellow' },
  hmbc: { positiveColor: 'darkviolet', negativeColor: 'yellow' }
};

export const hasPredefine2DColor = (experiment: string): boolean =>
  color2D.hasOwnProperty(experiment);

export const percentToHex = (p: number): string => {
  const percent = Math.max(0, Math.min(100, p));
  const intValue = Math.round((percent / 100) * 255);
  const hexValue = intValue.toString(16);

  return percent === 100 ? '' : hexValue.padStart(2, '0');
};

export const adjustAlpha = (color: string, factor: number): string => color + percentToHex(factor);

export const getColor = (isRandom = false, usedColors: string[] = [], opacity = 100): string => {
  const resetColors = COLORS.filter((c) => !usedColors.includes(c));

  if (resetColors.length > 0 && !isRandom) {
    return resetColors[0] + percentToHex(opacity);
  }
  const lum = -0.25;
  let hex = String(`#${Math.random().toString(16).slice(2, 8).toUpperCase()}`).replace(
    /[^\da-f]/gi,
    ''
  );

  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let rgb = '#';
  let c;
  let i;

  for (i = 0; i < 3; i++) {
    c = Number.parseInt(hex.slice(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.slice(c.length);
  }

  return rgb + percentToHex(opacity);
};

export const get2DColor = (
  experiment: string,
  colors: string[] = []
): { positiveColor: string; negativeColor: string } => {
  if (!color2D.hasOwnProperty(experiment)) {
    const positiveColor = getColor(false, colors);
    const negativeColor = adjustAlpha(positiveColor, 50);

    return { positiveColor, negativeColor };
  }

  return color2D[experiment];
};
