import { Obj } from '@splotch/core-utils';
import React, { useContext } from 'react';
import { Button, Col, Popover, Row } from 'react-bootstrap';
import { CHART_EXPORT_TYPES } from '../../../constants';
import { FixedWidthPopover } from '../_.styled';
import { SpectrumContext } from '../spectrum-store';

export const Exporting = (): React.ReactElement => {
  const [{ chartRef }] = useContext(SpectrumContext);

  const exportChart = (
    type: 'application/pdf' | 'image/jpeg' | 'image/png' | 'image/svg+xml'
  ): void => {
    chartRef?.current?.chart.exportChartLocal(
      { type },
      {
        boost: {
          enabled: false
        },
        title: {
          text: undefined
        },
        credits: {
          enabled: true,
          text: 'Powered by Splotch Labs',
          style: {
            fontSize: '16px'
          },
          href: 'https://splotch.io'
        }
      }
    );
  };

  return (
    <>
      <Popover.Title as='h2'>Export Graph as Image</Popover.Title>
      <FixedWidthPopover className='text-left'>
        {Obj.keys(CHART_EXPORT_TYPES).map((type) => {
          const exportType = CHART_EXPORT_TYPES[type];

          return (
            <Row className='mb-2' key={`export-${type}`}>
              <Col>
                <Button
                  variant='outline-info'
                  onClick={(): void => {
                    exportChart(exportType.mimeType);
                  }}
                >
                  {type}
                </Button>{' '}
                - {exportType.description}
              </Col>
            </Row>
          );
        })}
      </FixedWidthPopover>
    </>
  );
};
