import validator from 'validator';
import { Str } from './str';

export type EmailAddress = string;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace EmailAddress {
  export const normalize = (value: unknown): EmailAddress | undefined => {
    const stringVal = Str.normalize(value);

    return stringVal && validator.isEmail(stringVal) ? stringVal.toLowerCase() : undefined;
  };

  export const isValid = (value?: unknown): boolean => !!normalize(value);
}
