import { reimPhaseCorrection } from 'ml-spectra-processing';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace PhaseCorrection {
  export const id = 'phaseCorrection';
  export const name = 'Phase correction';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !datum.info.isFid;

  interface Options {
    ph0: number;
    ph1: number;
  }

  export const reduce = (previousValue: Options, newValue: Options): Filter.ReduceResult => {
    const reduced: Options = {
      ph0: previousValue.ph0 + newValue.ph0,
      ph1: previousValue.ph1 + newValue.ph1
    };

    return {
      once: true,
      reduce: reduced
    };
  };

  export const apply = (datum: Spectrum1D.Datum, options: Options = { ph0: 0, ph1: 0 }): void => {
    if (!isApplicable(datum)) {
      throw new Error('phaseCorrection not applicable on this data');
    }
    let { ph0, ph1 } = options;

    ph0 *= Math.PI / 180.0;
    ph1 *= Math.PI / 180.0;
    Object.assign(datum.data, reimPhaseCorrection(datum.data, ph0, ph1));
  };
}
