import { Nix } from './nix';

export namespace Arr {
  /**
   * Wrapper around `Array.isArray` that narrows to the specified type.
   *
   * @param value Value to query.
   * @returns Type assertion.
   */
  export const isArray = <T>(value: unknown | readonly T[]): value is readonly T[] =>
    Array.isArray(value);

  /**
   * Converts the value to an array if it is not already one. If the specified value is an array,
   * it is returned as-is. If the specified value is not an array, it returned in an array of length 1.
   * If the specified value is undefined, an empty array is returned.
   *
   * @param value The value to be normalized
   * @returns The normalized array.
   */
  export const normalize = <T>(value?: T | readonly T[]): readonly T[] => {
    let arr: readonly T[];

    if (Nix.isNil(value)) {
      arr = [];
    } else if (isArray(value)) {
      arr = value;
    } else {
      arr = [value];
    }

    return arr;
  };

  /**
   * Removes all of the elements of the specified array that are `null` or `undefined`.
   * This function is equivalent to calling Array.filter(Nix.isNotNil)
   *
   * @param value The array to be winnowed.
   * @returns A copy of the original array with all `null | undefined` elements removed.
   */

  export const winnow = <T>(value: readonly T[]): readonly T[] =>
    normalize(value).filter(Nix.isNotNil);
}
