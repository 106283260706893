import type { Theme } from '@splotch/core-ui';

interface Point {
  x: number;
  y: number;
}

const LEFT_INTEGRATION_LIMIT = (point: Point, theme: Theme): string => `
    <table>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Shift: </td>
        <td style='text-align: right'><b>${point.x.toFixed(4)}</b></td>
      </tr>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Intensity: </td>
        <td style='text-align: right'><b>${point.y.toFixed(4)}</b></td>
      </tr>
    </table>
    <div>
      Click to select the peak's <strong style='color: ${theme.colors.primary.hex()}'>left</strong> limit
    </div>
  `;

const RIGHT_INTEGRATION_LIMIT = (point: Point, theme: Theme): string => `
    <table>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Shift: </td>
        <td style='text-align: right'><b>${point.x.toFixed(4)}</b></td>
      </tr>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Intensity: </td>
        <td style='text-align: right'><b>${point.y.toFixed(4)}</b></td>
      </tr>
    </table>
    <div>
      Click to select the peak's <strong style='color: ${theme.colors.primary.hex()}'>right</strong> limit
    </div>
  `;

const FIRST_COUPLING_PEAK = (point: Point, theme: Theme): string => `
    <table>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Shift: </td>
        <td style='text-align: right'><b>${point.x.toFixed(4)}</b></td>
      </tr>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Intensity: </td>
        <td style='text-align: right'><b>${point.y.toFixed(4)}</b></td>
      </tr>
    </table>
    <div>
      Click to select the <strong style='color: ${theme.colors.primary.hex()}'>first</strong> location to use for the coupling constant
    </div>
  `;

const SECOND_COUPLING_PEAK = (point: Point, theme: Theme): string => `
    <table>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Shift: </td>
        <td style='text-align: right'><b>${point.x.toFixed(4)}</b></td>
      </tr>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Intensity: </td>
        <td style='text-align: right'><b>${point.y.toFixed(4)}</b></td>
      </tr>
    </table>
    <div>
      Click to select the <strong style='color: ${theme.colors.primary.hex()}'>second</strong> location to use for the coupling constant
    </div>
  `;

const STANDARD_CURSOR = (point: Point, theme: Theme): string => `
    <table>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Shift: </td>
        <td style='text-align: right'><b>${point.x.toFixed(4)}</b></td>
      </tr>
      <tr>
        <td style='color: ${theme.colors.primary.hex()}'>Intensity: </td>
        <td style='text-align: right'><b>${point.y.toFixed(4)}</b></td>
      </tr>
    </table>
  `;

export const Tooltips = {
  LEFT_INTEGRATION_LIMIT,
  RIGHT_INTEGRATION_LIMIT,
  FIRST_COUPLING_PEAK,
  SECOND_COUPLING_PEAK,
  STANDARD_CURSOR
};
