import type { ConditionalKeys, ConditionalPick, ValueOf } from 'type-fest';
import { Nix } from './nix';
import { Str } from './str';

const reduceKey = (value: unknown): string | undefined => {
  let result;

  if (Nix.isNotNil(value)) {
    const stringVal = Str.normalize(value);

    if (stringVal) {
      const reduced = stringVal.toLowerCase().replace(/[^\da-z]/g, '');

      result = reduced.length ? reduced : undefined;
    }
  }

  return result;
};

export namespace Enum {
  export type Enumeration = Record<number | string, unknown>;

  /**
   * Returns the enumerable keys for the specified enumeration. Enumerable keys are those which
   * are not number like and those whose associated value is a `string | number`.
   *
   * For example, consider the following example:
   *
   *  enum Example {
   *    ALPHA = 'red',
   *    BETA = 'orange',
   *    GAMMA = 3
   *  }
   *
   *  namespace Example {
   *    export const b = true;
   *    export const fn = () => 'hello';
   *  }
   *
   * The enumerable keys for this enumeration are: [`ALPHA`, `BETA`, `GAMMA`]. In this example, the
   * reverse-lookup key `3` is not enumerable, nor are the namespaced exports `b` and `fn`;
   *
   * @param obj Enumeration whose enumerable keys are to be returned.
   * @returns Array of enumerable keys.
   */
  export const keys = <E extends Enumeration, K extends ConditionalKeys<E, number | string>>(
    obj: E
  ): K[] =>
    <K[]>(
      (<unknown>(
        Object.keys(obj).filter(
          (k) => Number.isNaN(Number(k)) && ['string', 'number'].includes(typeof obj[k])
        )
      ))
    );

  /**
   * Returns the enumerable value that 'matches' the specified value or `undefined` if no match is found.
   * Values will be matched on both enumerable key names and enumerable values. Matching is case-insenstive
   * and ignores all non-alphanumeric characters.
   *
   * For example, consider the following example:
   *
   *  enum Example {
   *    ALPHA = 'red',
   *    BETA = 'orange',
   *    GAMMA = 3
   *  }
   *
   *  Enum.normalize(Example, ' RED ' ) => Example.ALPHA
   *  Enum.normalize(Example, ' alpha ') => Example.ALPHA
   *  Enum.normalize(Example, 3) = Example.GAMMA
   *  Enum.normalize(Example, ' ~!gamma!~ ') => Example.GAMMA
   *
   * @param obj Enumeration whose enumerable keys/values are to be matched.
   * @param value Value on which to match.
   * @returns Matching enumerable value or `undefined`.
   */
  export const normalize = <
    E extends Enumeration,
    P = ConditionalPick<E, number | string>,
    V = ValueOf<P, keyof P>
  >(
    obj: E,
    value: unknown
  ): V | undefined => {
    let result: V | undefined;

    const candidate = reduceKey(value);

    if (candidate) {
      const match = keys(obj).find((k: keyof E) =>
        [reduceKey(obj[k]), reduceKey(k)].includes(candidate)
      );

      result = match ? <V>obj[match] : undefined;
    }

    return result;
  };
}
