import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, InfoPageWrapper } from '../components';

export const FaqRoute = (): React.ReactElement => (
  <InfoPageWrapper>
    <div className='info-content flex-grow-1'>
      <h2 className='py-3 m-0'>Frequently Asked Questions</h2>

      <ul>
        <li>
          <p className='font-weight-bold'>
            What is <i>Splotch Labs</i>?
          </p>
          <p className='text-muted ml-5'>
            Splotch Labs is an educational web application that allows users to upload or browse NMR
            data, analyze spectra, and generate the supporting info for that data according to ACS
            NMR guidelines.
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>Where can I learn more about NMR?</p>
          <p className='text-muted ml-5'>
            We have created a list of additional learning resources for students and researchers
            looking to learn more. Check them out <Link to='/resources'>here</Link>!
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>Am I able to use Splotch Labs for free?</p>
          <p className='text-muted ml-5'>
            Absolutely- we are currently in the beta phase, which we are allowing the public to
            access for free. In the meantime, we&apos;d love to{' '}
            <Link to='/contact'>get your feedback</Link> about what you like and room for
            improvement!
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>Who/what is Splotch?</p>
          <p className='text-muted ml-5'>
            We are a small team of individuals that share a desire to use our skills to offer
            modern, powerful tools to researchers in the hard sciences. We feel that many of the
            software solutions offered to scientists are often outdated, difficult to use, poorly
            supported, and expensive- we are here to change that. Head to our{' '}
            <Link to='https://splotch.io/'>home page</Link> to find out more about the creators!
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>
            I would like to collaborate with the makers of Splotch Labs...
          </p>
          <p className='text-muted ml-5'>
            As a community-driven development project, we are very open to collaborative efforts and
            we would be happy to discuss proposals. Feel free to reach out to{' '}
            <Link to='mailto:support@splotch.io'>support@splotch.io</Link> if you are interested in
            getting involved!
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>I had a problem while processing my NMR spectrum...</p>
          <p className='text-muted ml-5'>
            Feel free to check out our <Link to='/tutorial'>tutorial</Link>, or if your question
            isn&apos;t addressed there, don&apos;t hesitate to <Link to='/contact'>contact us</Link>{' '}
            with questions! If you think you are seeing a bug in the application, please submit a{' '}
            <Link to='/bug-report'>bug report</Link> with as much detail as you are able and we will
            follow up with you as soon as possible.
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>
            What kinds of data does Splotch collect about my usage?
          </p>
          <p className='text-muted ml-5'>
            Definitely take a look at our
            <Link to='/legal/privacy'>privacy policy</Link> if you have questions about any tracking
            behavior, or reach out to us via e-mail with any additional questions you have!
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>
            How do I cite <i>Splotch Labs</i>?
          </p>
          <p className='text-muted ml-5'>
            If a citation is required in any context for assets exported from <i>Splotch Labs</i>,
            please feel free to use credit us however you see fit.
          </p>
        </li>

        <li>
          <p className='font-weight-bold'>I have another question that isn&apos;t listed here...</p>
          <p className='text-muted ml-5'>
            We love to hear from our users! Please feel free to{' '}
            <Link to='/contact'>reach out to us</Link> about any issues regarding Splotch Labs and
            we&apos;ll respond shortly :)
          </p>
        </li>
      </ul>
    </div>
    <Footer />
  </InfoPageWrapper>
);
