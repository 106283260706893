import { faShareSquare, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Obj } from '@splotch/core-utils';
import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Icons } from '../../components';
import { KEY_MAP } from '../../constants';
import { HelpModalWrapper } from './_.styled';

interface Props {
  show: boolean;
  onHide: () => void;
}

export const HelpModal = ({ show, onHide }: Props): React.ReactElement => {
  const handleClose = (): void => {
    onHide();
  };

  return (
    <HelpModalWrapper show={show} onHide={handleClose} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>How to Use Splotch Labs</h4>
        <ul>
          <li>
            <h5>Graph</h5>
            <p>
              You can always click and drag a region on the graph to zoom in. Hit the &apos;Reset
              Zoom&apos; button at the top right to go back to the full view.{' '}
            </p>
            <p>
              To pan the graph once you&apos;re zoomed in, hold down the &#8984; on a Mac or the
              Windows key on Windows, and drag.{' '}
            </p>
          </li>
          <li>
            <h5>
              Phase Correction - <Icons.PhasingIcon size={24} />
            </h5>
            <p>Begin by doing a Zero and First Order Correction as needed. </p>
          </li>
          <li>
            <h5>
              Peak Analysis - <Icons.PeakPickingIcon size={24} />
            </h5>
            <p>
              Start Peak Analysis to select left and right boundaries for each peak on the graph.
              You can adjust the Peak Range manually by typing into the input boxes.{' '}
            </p>
            <p>Be sure to select each peak&apos;s multiplicity from the drop down.</p>
            <p>
              To calculate the Coupling Factor (Δppm) on the graph, hit the mouse icon. Then, click
              on the two split peaks to use in the calculation. Repeat this process to calculate
              multiple Coupling Factors for a given peak. You can also manually type Coupling
              Factor(s) into the input box as a comma separated list.{' '}
            </p>
          </li>
          <li>
            <h5>
              Supporting Info - <FontAwesomeIcon className='text-tertiary' icon={faSitemap} />
            </h5>
            <p>
              This window will generate ACS formatted NMR supporting information based on the
              information in the peak picking table. Enter your solvent, machine frequency, and hit
              Calculate!{' '}
            </p>
            <p>NOTE: Be sure all peaks have a multiplicity selected.</p>
          </li>
          <li>
            <h5>
              Exporting - <FontAwesomeIcon className='text-tertiary' icon={faShareSquare} />
            </h5>
            <p>Export the graph in JPEG, PNG, PDF, or SVG formats. </p>
          </li>
        </ul>
        <h4>Keyboard Shortcuts</h4>
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th>Function</th>
              <th>Key</th>
            </tr>
          </thead>
          <tbody>
            {Obj.keys(KEY_MAP).map((key) => {
              const shortcut = KEY_MAP[key];

              return (
                <tr key={key}>
                  <td>{shortcut.description}</td>
                  <td>
                    <pre>{shortcut.key.toUpperCase()}</pre>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </HelpModalWrapper>
  );
};
