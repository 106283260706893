import type { Theme } from '@splotch/core-ui';
import { Grid } from '@splotch/core-ui';
import Color from 'color';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

export const GetInvolvedContainer = styled.div`
  background-color: ${({ theme }: { theme: Theme }): string =>
    Color(theme.colors.secondary).lighten(0.7).alpha(0.25).toString()};

  .get-involved-content {
    width: var(--max-page-width);
    margin: 0 auto;
  }
`;

export const LinksTable = styled(Grid)`
  padding-bottom: 64px;
  justify-items: center;
`;

export const CardHyperlink = styled(Link)`
  color: inherit;

  :hover {
    color: inherit;
    text-decoration: none;
  }
`;

export const StyledCard = styled(Card)`
  width: 18rem;
  height: 100%;

  ${({ theme }: { theme: Theme }): SimpleInterpolation => css`
    border: 2px solid ${Color(theme.colors.primary).alpha(0.25).toString()};

    :hover {
      color: inherit;
      border-color: var(--primary);
      text-decoration: none;
    }
  `}

  img {
    width: 75%;
    align-self: center;
  }
`;
