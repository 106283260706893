import { useEffect, useMemo, useState } from 'react';

export interface Alert {
  message?: string;
  show: boolean;
  hide: () => void;
}

export namespace Alert {
  export const useAlert = (error?: Error): Alert => {
    const [message, setMessage] = useState<string | undefined>();
    const [show, setShow] = useState(false);

    useEffect(() => {
      setMessage(error?.message);
      setShow(!!error);
    }, [error]);

    return useMemo(
      () => ({
        message,
        show,
        hide(): void {
          setShow(false);
        }
      }),
      [message, show]
    );
  };
}
