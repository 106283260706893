import { Enum } from './enum';

export namespace Calendar {
  export enum Unit {
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months'
  }

  export namespace Unit {
    export const normalize = (value: unknown): Unit | undefined => Enum.normalize(Unit, value);
  }

  export enum Weekday {
    SUNDAY = 'Sunday',
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday'
  }

  export namespace Weekday {
    export const normalize = (value: unknown): Weekday | undefined =>
      Enum.normalize(Weekday, value);
  }
}
