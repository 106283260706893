import cx from 'classnames';
import React from 'react';
import { Delay } from '../components';

/*
 * This component is different than the standard loading indicator in that it needs
 * to work before we have injected our styles.  Old school html+css!
 */
export const Spinner = ({ spinnerClass, ...rest }: Spinner.Props): React.ReactElement => (
  <Delay>
    <div {...rest}>
      <i className={cx(spinnerClass, 'fad', 'fa-cog', 'fa-spin', 'fa-6x')} />
    </div>
  </Delay>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Spinner {
  export interface Props {
    style?: Record<string, string>;
    spinnerClass?: string;
  }
}
