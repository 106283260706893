import type { JsonArray, JsonObject, JsonValue } from 'type-fest';
import { Nix } from './nix';
import { Obj } from './obj';
import { Str } from './str';

/* eslint-disable @typescript-eslint/ban-types */

export namespace JaySON {
  export type Object = JsonObject;
  export type Value = JsonValue;
  export type Array = JsonArray;

  /**
   * Converts a JSON string into an value. This function is just a wrapper around
   * `JSON.parse` that eats exceptions.
   *
   * @param value Value to be parsed.
   * @param extra See `JSON.parse`
   * @returns The parsed value or `undefined` if the value could not be parsed.
   */
  export const parse = (
    value: string,
    extra?: Parameters<typeof JSON.parse>[1]
  ): Value | undefined => {
    const stringVal = Str.normalize(value);

    let result: Value | undefined;

    if (stringVal) {
      try {
        result = <Value>JSON.parse(stringVal, extra);
      } catch {
        // ignore
      }
    }

    return result;
  };

  /**
   * Converts the specified value to a JSON string.  This function is just a wrapper around
   * `JSON.stringify` that property handles `undefined`.
   *
   * @param value Value to be converted to a JSON string.
   * @returns JSON string or `Str.NULL` for null or undefined strings.
   */
  export const stringify = (value: unknown): string =>
    Nix.isNotNil(value) ? JSON.stringify(value) : Str.NULL;

  /**
   * Converts the specified value to a `JaySON.Object`. This method simply calls `Obj.normalize`
   * and casts the return type.  Note that if you pass this function a scalar JSON value (for example, a
   * `string`), it will returned `undefined`.
   *
   * @param value Value to be normalized.
   * @returns The normalized object or `undefined` if the object could not be normaled.
   */
  export const toObject = <T extends Object>(value: unknown): T | undefined =>
    Obj.normalize<T>(value);
}
