import type { Any } from '@splotch/core-utils';
import equallySpaced from 'ml-array-xy-equally-spaced';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace EquallySpaced {
  export const id = 'equallySpaced';
  export const name = 'Equally Spaced';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !datum.info.isFid;

  export const reduce = (): Filter.ReduceResult => ({
    once: false
  });

  interface Options {
    from?: number;
    to?: number;
    numberOfPoints?: number;
    exclusions?: Any[];
  }

  export const apply = (datum: Spectrum1D.Datum, options: Options = {}): void => {
    if (!isApplicable(datum)) {
      throw new Error('Equally Spaced is not applicable on this data');
    }
    const { from, to, numberOfPoints, exclusions } = options;
    const { x, re, im } = datum.data;
    const XREdata = equallySpaced({ x, y: re }, { from, to, numberOfPoints, exclusions });

    datum.data.x = Float64Array.from(XREdata.x);
    datum.data.re = Float64Array.from(XREdata.y);

    const XIMdata = equallySpaced({ x, y: im }, { from, to, numberOfPoints, exclusions });

    datum.data.im = Float64Array.from(XIMdata.y);
  };
}
