import React from 'react';
import { Card } from 'react-bootstrap';
import { CardHyperlink, GetInvolvedContainer, LinksTable, StyledCard } from './__styled';

const links = [
  {
    to: '/tutorial',
    title: 'Tutorial',
    icon: 'assets/tutorial.png',
    text:
      'New to Splotch Labs? Get started processing your laboratory NMR data and save the results for use in your publications'
  },
  {
    to: '/resources',
    title: 'Resources',
    icon: 'assets/books.png',
    text:
      "Interested in learning more? We've put together a list of resources for students and researchers learning to analyze NMR spectra"
  },
  {
    to: '/feature-request',
    title: 'Feature Requests',
    icon: 'assets/feature-request.png',
    text:
      'We love getting feedback from our users - let us know if there are any additions you would like to see in Splotch Labs!'
  }
];

export const GetInvolved = (): React.ReactElement => (
  <GetInvolvedContainer>
    <div className='get-involved-content'>
      <h2 className='py-3 m-0 text-center'>Get Involved</h2>
      <p className='text-dark'>
        Our mission is to build quality tools that allow scientists to conduct their research more
        effectively- with that in mind, we are doing our part to help the next generation of
        scientists in their journey. Check out some of the learning resources we&apos;ve compiled if
        you&apos;re new to NMR and want to learn more, or take a look at our tutorial if you&apos;re
        new to Splotch Labs.
      </p>
      <p className='text-dark'>
        Lastly, we are continuously developing and releasing new features for Splotch Labs, feel
        free to take a look at our{' '}
        <a href='https://trello.com/b/x7G86Q8O/splotchio-roadmap'>Feature Backlog</a> or request a
        feature that would help you get more out of the application!
      </p>
      <LinksTable gridTemplateColumns='1fr 1fr 1fr'>
        {links.map((link) => (
          <CardHyperlink to={link.to} key={link.title}>
            <StyledCard>
              <Card.Img variant='top' src={link.icon} />
              <Card.Body>
                <Card.Title>{link.title}</Card.Title>
                <Card.Text className='text-muted'>{link.text}</Card.Text>
              </Card.Body>
            </StyledCard>
          </CardHyperlink>
        ))}
      </LinksTable>
    </div>
  </GetInvolvedContainer>
);
